export default [
    {
        title: "Aa en Hunze",
        province: "Drenthe",
        cbs_code: "1680",
        population: 25728,
        area: 276.09,
        migrants: 6.7,
        migrants_western: 4.2,
        migrants_non_western: 2.5,
        income: 31100,
        latitude: 53,
        longitude: 6.75,
    },
    {
        title: "Aalsmeer",
        province: "Noord-Holland",
        cbs_code: "0358",
        population: 33071,
        area: 20.12,
        migrants: 20.4,
        migrants_western: 11.3,
        migrants_non_western: 9.1,
        income: 35300,
        latitude: 52.233333333333,
        longitude: 4.75,
    },
    {
        title: "Aalten",
        province: "Gelderland",
        cbs_code: "0197",
        population: 27269,
        area: 96.54,
        migrants: 11.4,
        migrants_western: 8,
        migrants_non_western: 3.4,
        income: 28600,
        latitude: 51.933333333333,
        longitude: 6.5833333333333,
    },
    {
        title: "Achtkarspelen",
        province: "Friesland",
        cbs_code: "0059",
        population: 28110,
        area: 102.23,
        migrants: 4.5,
        migrants_western: 2.3,
        migrants_non_western: 2.2,
        income: 26100,
        latitude: 53.416666666667,
        longitude: 6.25,
    },
    {
        title: "Alblasserdam",
        province: "Zuid-Holland",
        cbs_code: "0482",
        population: 20399,
        area: 8.78,
        migrants: 15.1,
        migrants_western: 7,
        migrants_non_western: 8.1,
        income: 29400,
        latitude: 51.866666666667,
        longitude: 4.6666666666667,
    },
    {
        title: "Albrandswaard",
        province: "Zuid-Holland",
        cbs_code: "0613",
        population: 26349,
        area: 21.69,
        migrants: 21.5,
        migrants_western: 7.9,
        migrants_non_western: 13.6,
        income: 35200,
        latitude: 51.866666666667,
        longitude: 4.4166666666667,
    },
    {
        title: "Alkmaar",
        province: "Noord-Holland",
        cbs_code: "0361",
        population: 111878,
        area: 110.46,
        migrants: 22.6,
        migrants_western: 9.8,
        migrants_non_western: 12.9,
        income: 29100,
        latitude: 52.616666666667,
        longitude: 4.7666666666667,
    },
    {
        title: "Almelo",
        province: "Overijssel",
        cbs_code: "0141",
        population: 74034,
        area: 67.27,
        migrants: 26.3,
        migrants_western: 10.4,
        migrants_non_western: 15.9,
        income: 26300,
        latitude: 52.366666666667,
        longitude: 6.6666666666667,
    },
    {
        title: "Almere",
        province: "Flevoland",
        cbs_code: "0034",
        population: 223183,
        area: 129.19,
        migrants: 43.8,
        migrants_western: 10.9,
        migrants_non_western: 32.9,
        income: 29400,
        latitude: 52.366666666667,
        longitude: 5.2166666666667,
    },
    {
        title: "Alphen aan den Rijn",
        province: "Zuid-Holland",
        cbs_code: "0484",
        population: 114313,
        area: 126.23,
        migrants: 20.8,
        migrants_western: 9.4,
        migrants_non_western: 11.4,
        income: 31400,
        latitude: 52.133333333333,
        longitude: 4.65,
    },
    {
        title: "Alphen-Chaam",
        province: "Noord-Brabant",
        cbs_code: "1723",
        population: 10462,
        area: 93.04,
        migrants: 14.5,
        migrants_western: 11.8,
        migrants_non_western: 2.8,
        income: 33000,
        latitude: 51.483333333333,
        longitude: 4.8666666666667,
    },
    {
        title: "Altena",
        province: "Noord-Brabant",
        cbs_code: "1959",
        population: 57776,
        area: 200.63,
        migrants: 7.5,
        migrants_western: 4.7,
        migrants_non_western: 2.8,
        income: 31100,
        latitude: 51.766666666667,
        longitude: 4.95,
    },
    {
        title: "Ameland",
        province: "Friesland",
        cbs_code: "0060",
        population: 3834,
        area: 59.11,
        migrants: 8.1,
        migrants_western: 6.5,
        migrants_non_western: 1.6,
        income: 0,
        latitude: 53.45,
        longitude: 5.75,
    },
    {
        title: "Amersfoort",
        province: "Utrecht",
        cbs_code: "0307",
        population: 160902,
        area: 62.62,
        migrants: 25.1,
        migrants_western: 8.9,
        migrants_non_western: 16.1,
        income: 31500,
        latitude: 52.15,
        longitude: 5.3833333333333,
    },
    {
        title: "Amstelveen",
        province: "Noord-Holland",
        cbs_code: "0362",
        population: 94396,
        area: 41.13,
        migrants: 45.8,
        migrants_western: 19.7,
        migrants_non_western: 26.1,
        income: 33500,
        latitude: 52.3,
        longitude: 4.8333333333333,
    },
    {
        title: "Amsterdam",
        province: "Noord-Holland",
        cbs_code: "0363",
        population: 922679,
        area: 165.5,
        migrants: 55.6,
        migrants_western: 19.5,
        migrants_non_western: 36.1,
        income: 29400,
        latitude: 52.366666666667,
        longitude: 4.8833333333333,
    },
    {
        title: "Apeldoorn",
        province: "Gelderland",
        cbs_code: "0200",
        population: 167205,
        area: 339.89,
        migrants: 18.1,
        migrants_western: 8.4,
        migrants_non_western: 9.7,
        income: 29700,
        latitude: 52.216666666667,
        longitude: 5.95,
    },
    {
        title: "Arnhem",
        province: "Gelderland",
        cbs_code: "0202",
        population: 165896,
        area: 97.82,
        migrants: 32.8,
        migrants_western: 11.8,
        migrants_non_western: 21,
        income: 26100,
        latitude: 51.983333333333,
        longitude: 5.9166666666667,
    },
    {
        title: "Assen",
        province: "Drenthe",
        cbs_code: "0106",
        population: 69426,
        area: 81.89,
        migrants: 16.2,
        migrants_western: 8,
        migrants_non_western: 8.3,
        income: 27700,
        latitude: 52.983333333333,
        longitude: 6.55,
    },
    {
        title: "Asten",
        province: "Noord-Brabant",
        cbs_code: "0743",
        population: 17267,
        area: 70.17,
        migrants: 11.3,
        migrants_western: 7.6,
        migrants_non_western: 3.7,
        income: 30300,
        latitude: 51.4,
        longitude: 5.7333333333333,
    },
    {
        title: "Baarle-Nassau",
        province: "Noord-Brabant",
        cbs_code: "0744",
        population: 7080,
        area: 76.14,
        migrants: 25.8,
        migrants_western: 22.7,
        migrants_non_western: 3.1,
        income: 31200,
        latitude: 51.45,
        longitude: 4.9333333333333,
    },
    {
        title: "Baarn",
        province: "Utrecht",
        cbs_code: "0308",
        population: 25032,
        area: 32.54,
        migrants: 18.5,
        migrants_western: 9.8,
        migrants_non_western: 8.6,
        income: 37400,
        latitude: 52.216666666667,
        longitude: 5.3,
    },
    {
        title: "Barendrecht",
        province: "Zuid-Holland",
        cbs_code: "0489",
        population: 48807,
        area: 19.75,
        migrants: 25.2,
        migrants_western: 7.8,
        migrants_non_western: 17.4,
        income: 34300,
        latitude: 51.85,
        longitude: 4.5333333333333,
    },
    {
        title: "Barneveld",
        province: "Gelderland",
        cbs_code: "0203",
        population: 61769,
        area: 175.9,
        migrants: 8.9,
        migrants_western: 4.1,
        migrants_non_western: 4.8,
        income: 31100,
        latitude: 52.133333333333,
        longitude: 5.5833333333333,
    },
    {
        title: "Beek",
        province: "Limburg",
        cbs_code: "0888",
        population: 16152,
        area: 21.03,
        migrants: 15.2,
        migrants_western: 10.3,
        migrants_non_western: 5,
        income: 30400,
        latitude: 50.933333333333,
        longitude: 5.7833333333333,
    },
    {
        title: "Beekdaelen",
        province: "Limburg",
        cbs_code: "1954",
        population: 35941,
        area: 78.3,
        migrants: 15.7,
        migrants_western: 12.4,
        migrants_non_western: 3.3,
        income: 30300,
        latitude: 50.933333333333,
        longitude: 5.8833333333333,
    },
    {
        title: "Beesel",
        province: "Limburg",
        cbs_code: "0889",
        population: 13450,
        area: 27.99,
        migrants: 16.9,
        migrants_western: 11.2,
        migrants_non_western: 5.7,
        income: 28500,
        latitude: 51.266666666667,
        longitude: 6.0333333333333,
    },
    {
        title: "Berg en Dal",
        province: "Gelderland",
        cbs_code: "1945",
        population: 35439,
        area: 86.31,
        migrants: 14.5,
        migrants_western: 10.2,
        migrants_non_western: 4.3,
        income: 29600,
        latitude: 51.783333333333,
        longitude: 5.9333333333333,
    },
    {
        title: "Bergeijk",
        province: "Noord-Brabant",
        cbs_code: "1724",
        population: 19089,
        area: 101,
        migrants: 10.8,
        migrants_western: 8.1,
        migrants_non_western: 2.6,
        income: 32700,
        latitude: 51.316666666667,
        longitude: 5.35,
    },
    {
        title: "Bergen",
        province: "Limburg",
        cbs_code: "0893",
        population: 13100,
        area: 103.24,
        migrants: 15.8,
        migrants_western: 12.9,
        migrants_non_western: 2.9,
        income: 28900,
        latitude: 51.583333333333,
        longitude: 6.0333333333333,
    },
    {
        title: "Bergen",
        province: "Noord-Holland",
        cbs_code: "0373",
        population: 30133,
        area: 98.96,
        migrants: 13.6,
        migrants_western: 9.8,
        migrants_non_western: 3.9,
        income: 37300,
        latitude: 52.666666666667,
        longitude: 4.7,
    },
    {
        title: "Bergen op Zoom",
        province: "Noord-Brabant",
        cbs_code: "0748",
        population: 68970,
        area: 79.96,
        migrants: 25.7,
        migrants_western: 10.4,
        migrants_non_western: 15.3,
        income: 29000,
        latitude: 51.5,
        longitude: 4.2833333333333,
    },
    {
        title: "Berkelland",
        province: "Gelderland",
        cbs_code: "1859",
        population: 43992,
        area: 258.06,
        migrants: 8,
        migrants_western: 5,
        migrants_non_western: 2.9,
        income: 29300,
        latitude: 52.116666666667,
        longitude: 6.5166666666667,
    },
    {
        title: "Bernheze",
        province: "Noord-Brabant",
        cbs_code: "1721",
        population: 32277,
        area: 89.73,
        migrants: 9.2,
        migrants_western: 6.2,
        migrants_non_western: 3,
        income: 31900,
        latitude: 51.683333333333,
        longitude: 5.5166666666667,
    },
    {
        title: "Best",
        province: "Noord-Brabant",
        cbs_code: "0753",
        population: 30883,
        area: 34.3,
        migrants: 17.5,
        migrants_western: 9.9,
        migrants_non_western: 7.6,
        income: 32900,
        latitude: 51.5,
        longitude: 5.3833333333333,
    },
    {
        title: "Beuningen",
        province: "Gelderland",
        cbs_code: "0209",
        population: 26576,
        area: 43.65,
        migrants: 13.1,
        migrants_western: 7.6,
        migrants_non_western: 5.4,
        income: 31300,
        latitude: 51.85,
        longitude: 5.75,
    },
    {
        title: "Beverwijk",
        province: "Noord-Holland",
        cbs_code: "0375",
        population: 42678,
        area: 18.4,
        migrants: 27.7,
        migrants_western: 12.1,
        migrants_non_western: 15.6,
        income: 28700,
        latitude: 52.483333333333,
        longitude: 4.65,
    },
    {
        title: "Bladel",
        province: "Noord-Brabant",
        cbs_code: "1728",
        population: 21020,
        area: 75.33,
        migrants: 10.5,
        migrants_western: 7.3,
        migrants_non_western: 3.3,
        income: 31400,
        latitude: 51.366666666667,
        longitude: 5.2166666666667,
    },
    {
        title: "Blaricum",
        province: "Noord-Holland",
        cbs_code: "0376",
        population: 12486,
        area: 11.11,
        migrants: 19.8,
        migrants_western: 10.8,
        migrants_non_western: 8.9,
        income: 50700,
        latitude: 52.266666666667,
        longitude: 5.2333333333333,
    },
    {
        title: "Bloemendaal",
        province: "Noord-Holland",
        cbs_code: "0377",
        population: 23932,
        area: 39.79,
        migrants: 19.2,
        migrants_western: 13.9,
        migrants_non_western: 5.3,
        income: 51700,
        latitude: 52.4,
        longitude: 4.6166666666667,
    },
    {
        title: "Bodegraven-Reeuwijk",
        province: "Zuid-Holland",
        cbs_code: "1901",
        population: 36303,
        area: 75.38,
        migrants: 13.4,
        migrants_western: 6.6,
        migrants_non_western: 6.9,
        income: 34700,
        latitude: 52.05,
        longitude: 4.7166666666667,
    },
    {
        title: "Boekel",
        province: "Noord-Brabant",
        cbs_code: "0755",
        population: 11204,
        area: 34.51,
        migrants: 8.3,
        migrants_western: 6.2,
        migrants_non_western: 2.2,
        income: 31300,
        latitude: 51.6,
        longitude: 5.6666666666667,
    },
    {
        title: "Borger-Odoorn",
        province: "Drenthe",
        cbs_code: "1681",
        population: 25915,
        area: 274.53,
        migrants: 6.4,
        migrants_western: 4.3,
        migrants_non_western: 2.1,
        income: 28500,
        latitude: 52.85,
        longitude: 6.85,
    },
    {
        title: "Borne",
        province: "Overijssel",
        cbs_code: "0147",
        population: 24518,
        area: 26,
        migrants: 11,
        migrants_western: 6.3,
        migrants_non_western: 4.8,
        income: 30500,
        latitude: 52.3,
        longitude: 6.75,
    },
    {
        title: "Borsele",
        province: "Zeeland",
        cbs_code: "0654",
        population: 23164,
        area: 141.57,
        migrants: 9.5,
        migrants_western: 6.5,
        migrants_non_western: 3,
        income: 30600,
        latitude: 51.433333333333,
        longitude: 3.8166666666667,
    },
    {
        title: "Boxtel",
        province: "Noord-Brabant",
        cbs_code: "0757",
        population: 33828,
        area: 63.73,
        migrants: 16.9,
        migrants_western: 8.4,
        migrants_non_western: 8.5,
        income: 30300,
        latitude: 51.583333333333,
        longitude: 5.3333333333333,
    },
    {
        title: "Breda",
        province: "Noord-Brabant",
        cbs_code: "0758",
        population: 186504,
        area: 125.74,
        migrants: 24.2,
        migrants_western: 11.5,
        migrants_non_western: 12.8,
        income: 30300,
        latitude: 51.583333333333,
        longitude: 4.7833333333333,
    },
    {
        title: "Bronckhorst",
        province: "Gelderland",
        cbs_code: "1876",
        population: 36261,
        area: 283.5,
        migrants: 6.1,
        migrants_western: 4.1,
        migrants_non_western: 2,
        income: 32800,
        latitude: 52.05,
        longitude: 6.3,
    },
    {
        title: "Brummen",
        province: "Gelderland",
        cbs_code: "0213",
        population: 21109,
        area: 83.65,
        migrants: 12.1,
        migrants_western: 6.1,
        migrants_non_western: 6,
        income: 30700,
        latitude: 52.1,
        longitude: 6.1,
    },
    {
        title: "Brunssum",
        province: "Limburg",
        cbs_code: "0899",
        population: 27647,
        area: 17.25,
        migrants: 24.1,
        migrants_western: 18.5,
        migrants_non_western: 5.6,
        income: 26300,
        latitude: 50.95,
        longitude: 5.9666666666667,
    },
    {
        title: "Bunnik",
        province: "Utrecht",
        cbs_code: "0312",
        population: 15997,
        area: 36.97,
        migrants: 13.2,
        migrants_western: 7.6,
        migrants_non_western: 5.6,
        income: 35200,
        latitude: 52.066666666667,
        longitude: 5.2,
    },
    {
        title: "Bunschoten",
        province: "Utrecht",
        cbs_code: "0313",
        population: 22502,
        area: 30.38,
        migrants: 11.7,
        migrants_western: 6.3,
        migrants_non_western: 5.3,
        income: 31900,
        latitude: 52.25,
        longitude: 5.3666666666667,
    },
    {
        title: "Buren",
        province: "Gelderland",
        cbs_code: "0214",
        population: 27719,
        area: 133.89,
        migrants: 8.7,
        migrants_western: 5.9,
        migrants_non_western: 2.8,
        income: 33000,
        latitude: 51.9,
        longitude: 5.3333333333333,
    },
    {
        title: "Capelle aan den IJssel",
        province: "Zuid-Holland",
        cbs_code: "0502",
        population: 67513,
        area: 14.14,
        migrants: 36.2,
        migrants_western: 11.3,
        migrants_non_western: 24.9,
        income: 29100,
        latitude: 51.933333333333,
        longitude: 4.5833333333333,
    },
    {
        title: "Castricum",
        province: "Noord-Holland",
        cbs_code: "0383",
        population: 36298,
        area: 49.68,
        migrants: 11.7,
        migrants_western: 7.1,
        migrants_non_western: 4.5,
        income: 35500,
        latitude: 52.55,
        longitude: 4.6666666666667,
    },
    {
        title: "Coevorden",
        province: "Drenthe",
        cbs_code: "0109",
        population: 35741,
        area: 296.07,
        migrants: 9.2,
        migrants_western: 5.3,
        migrants_non_western: 3.9,
        income: 28700,
        latitude: 52.65,
        longitude: 6.7333333333333,
    },
    {
        title: "Cranendonck",
        province: "Noord-Brabant",
        cbs_code: "1706",
        population: 20879,
        area: 76.4,
        migrants: 18.2,
        migrants_western: 11.5,
        migrants_non_western: 6.7,
        income: 31300,
        latitude: 51.266666666667,
        longitude: 5.5666666666667,
    },
    {
        title: "Culemborg",
        province: "Gelderland",
        cbs_code: "0216",
        population: 29732,
        area: 29.29,
        migrants: 23.7,
        migrants_western: 9.3,
        migrants_non_western: 14.3,
        income: 30900,
        latitude: 51.95,
        longitude: 5.2333333333333,
    },
    {
        title: "Dalfsen",
        province: "Overijssel",
        cbs_code: "0148",
        population: 29599,
        area: 165.07,
        migrants: 5.9,
        migrants_western: 3.3,
        migrants_non_western: 2.6,
        income: 31200,
        latitude: 52.5,
        longitude: 6.25,
    },
    {
        title: "Dantumadeel",
        province: "Friesland",
        cbs_code: "1891",
        population: 19173,
        area: 84.66,
        migrants: 4.5,
        migrants_western: 2.2,
        migrants_non_western: 2.4,
        income: 26500,
        latitude: 53.283333333333,
        longitude: 5.9833333333333,
    },
    {
        title: "De Bilt",
        province: "Utrecht",
        cbs_code: "0310",
        population: 43871,
        area: 66.12,
        migrants: 18.3,
        migrants_western: 9.7,
        migrants_non_western: 8.6,
        income: 36200,
        latitude: 52.1,
        longitude: 5.1833333333333,
    },
    {
        title: "De Friese Meren",
        province: "Friesland",
        cbs_code: "1940",
        population: 51993,
        area: 351.29,
        migrants: 8.2,
        migrants_western: 4.6,
        migrants_non_western: 3.6,
        income: 28700,
        latitude: 52.933333333333,
        longitude: 5.7,
    },
    {
        title: "De Ronde Venen",
        province: "Utrecht",
        cbs_code: "0736",
        population: 45664,
        area: 99.92,
        migrants: 17.1,
        migrants_western: 8.9,
        migrants_non_western: 8.2,
        income: 35800,
        latitude: 52.2,
        longitude: 4.8666666666667,
    },
    {
        title: "De Wolden",
        province: "Drenthe",
        cbs_code: "1690",
        population: 24585,
        area: 224.55,
        migrants: 5.3,
        migrants_western: 3.2,
        migrants_non_western: 2.2,
        income: 30300,
        latitude: 52.666666666667,
        longitude: 6.4166666666667,
    },
    {
        title: "Delft",
        province: "Zuid-Holland",
        cbs_code: "0503",
        population: 106184,
        area: 22.65,
        migrants: 36.7,
        migrants_western: 15.2,
        migrants_non_western: 21.5,
        income: 28200,
        latitude: 52,
        longitude: 4.35,
    },
    {
        title: "Den Haag",
        province: "Zuid-Holland",
        cbs_code: "0518",
        population: 562446,
        area: 82.45,
        migrants: 55.6,
        migrants_western: 19.3,
        migrants_non_western: 36.3,
        income: 0,
        latitude: 52.083333333333,
        longitude: 4.3,
    },
    {
        title: "Den Helder",
        province: "Noord-Holland",
        cbs_code: "0400",
        population: 56524,
        area: 45.25,
        migrants: 20.5,
        migrants_western: 9.8,
        migrants_non_western: 10.7,
        income: 26400,
        latitude: 52.95,
        longitude: 4.7666666666667,
    },
    {
        title: "Deurne",
        province: "Noord-Brabant",
        cbs_code: "0762",
        population: 33010,
        area: 116.93,
        migrants: 9.7,
        migrants_western: 5.8,
        migrants_non_western: 3.9,
        income: 29500,
        latitude: 51.45,
        longitude: 5.7833333333333,
    },
    {
        title: "Deventer",
        province: "Overijssel",
        cbs_code: "0150",
        population: 102831,
        area: 130.68,
        migrants: 22.3,
        migrants_western: 9.1,
        migrants_non_western: 13.2,
        income: 27600,
        latitude: 52.266666666667,
        longitude: 6.2,
    },
    {
        title: "Diemen",
        province: "Noord-Holland",
        cbs_code: "0384",
        population: 32786,
        area: 11.99,
        migrants: 47.9,
        migrants_western: 17.5,
        migrants_non_western: 30.4,
        income: 29500,
        latitude: 52.333333333333,
        longitude: 4.95,
    },
    {
        title: "Dijk en Waard",
        province: "Noord-Holland",
        cbs_code: "1980",
        population: 89087,
        area: 62.09,
        migrants: 34.5,
        migrants_western: 15.6,
        migrants_non_western: 18.9,
        income: 0,
        latitude: 52.666666666667,
        longitude: 4.8333333333333,
    },
    {
        title: "Dinkelland",
        province: "Overijssel",
        cbs_code: "1774",
        population: 26746,
        area: 175.71,
        migrants: 8.1,
        migrants_western: 5.9,
        migrants_non_western: 2.2,
        income: 30800,
        latitude: 52.366666666667,
        longitude: 7,
    },
    {
        title: "Doesburg",
        province: "Gelderland",
        cbs_code: "0221",
        population: 11073,
        area: 11.53,
        migrants: 20.3,
        migrants_western: 9.6,
        migrants_non_western: 10.8,
        income: 26800,
        latitude: 52.016666666667,
        longitude: 6.1333333333333,
    },
    {
        title: "Doetinchem",
        province: "Gelderland",
        cbs_code: "0222",
        population: 59265,
        area: 79.05,
        migrants: 14.7,
        migrants_western: 7.4,
        migrants_non_western: 7.3,
        income: 28000,
        latitude: 51.966666666667,
        longitude: 6.2833333333333,
    },
    {
        title: "Dongen",
        province: "Noord-Brabant",
        cbs_code: "0766",
        population: 27252,
        area: 29.24,
        migrants: 14,
        migrants_western: 7,
        migrants_non_western: 7,
        income: 29800,
        latitude: 51.616666666667,
        longitude: 4.9333333333333,
    },
    {
        title: "Dordrecht",
        province: "Zuid-Holland",
        cbs_code: "0505",
        population: 121563,
        area: 78.54,
        migrants: 30.7,
        migrants_western: 11.6,
        migrants_non_western: 19.1,
        income: 27900,
        latitude: 51.8,
        longitude: 4.6666666666667,
    },
    {
        title: "Drechterland",
        province: "Noord-Holland",
        cbs_code: "0498",
        population: 20413,
        area: 58.89,
        migrants: 11,
        migrants_western: 7,
        migrants_non_western: 4,
        income: 31000,
        latitude: 52.683333333333,
        longitude: 5.1666666666667,
    },
    {
        title: "Drimmelen",
        province: "Noord-Brabant",
        cbs_code: "1719",
        population: 28030,
        area: 95.18,
        migrants: 9.8,
        migrants_western: 6.8,
        migrants_non_western: 3,
        income: 31300,
        latitude: 51.683333333333,
        longitude: 4.7833333333333,
    },
    {
        title: "Dronten",
        province: "Flevoland",
        cbs_code: "0303",
        population: 43523,
        area: 333.57,
        migrants: 17.6,
        migrants_western: 8.5,
        migrants_non_western: 9.1,
        income: 29000,
        latitude: 52.516666666667,
        longitude: 5.7166666666667,
    },
    {
        title: "Druten",
        province: "Gelderland",
        cbs_code: "0225",
        population: 19532,
        area: 37.64,
        migrants: 12.6,
        migrants_western: 6.4,
        migrants_non_western: 6.2,
        income: 29600,
        latitude: 51.883333333333,
        longitude: 5.6,
    },
    {
        title: "Duiven",
        province: "Gelderland",
        cbs_code: "0226",
        population: 24986,
        area: 33.9,
        migrants: 14.7,
        migrants_western: 8.4,
        migrants_non_western: 6.2,
        income: 29600,
        latitude: 51.95,
        longitude: 6.0166666666667,
    },
    {
        title: "Echt-Susteren",
        province: "Limburg",
        cbs_code: "1711",
        population: 32001,
        area: 103.07,
        migrants: 17.4,
        migrants_western: 13.2,
        migrants_non_western: 4.1,
        income: 29000,
        latitude: 51.083333333333,
        longitude: 5.8666666666667,
    },
    {
        title: "Edam-Volendam",
        province: "Noord-Holland",
        cbs_code: "0385",
        population: 36812,
        area: 54.33,
        migrants: 9.5,
        migrants_western: 4.4,
        migrants_non_western: 5.1,
        income: 33000,
        latitude: 52.5,
        longitude: 5.05,
    },
    {
        title: "Ede",
        province: "Gelderland",
        cbs_code: "0228",
        population: 122140,
        area: 318.18,
        migrants: 15.9,
        migrants_western: 7.2,
        migrants_non_western: 8.8,
        income: 30000,
        latitude: 52.05,
        longitude: 5.6666666666667,
    },
    {
        title: "Eemnes",
        province: "Utrecht",
        cbs_code: "0317",
        population: 9618,
        area: 31.04,
        migrants: 14,
        migrants_western: 7.6,
        migrants_non_western: 6.4,
        income: 33700,
        latitude: 52.25,
        longitude: 5.25,
    },
    {
        title: "Eemsdelta",
        province: "Groningen",
        cbs_code: "1979",
        population: 45317,
        area: 267.89,
        migrants: 0,
        migrants_western: 0,
        migrants_non_western: 0,
        income: 33700,
        latitude: 53.333333333333,
        longitude: 6.8333333333333,
    },
    {
        title: "Eersel",
        province: "Noord-Brabant",
        cbs_code: "0770",
        population: 19995,
        area: 82.46,
        migrants: 9.8,
        migrants_western: 6.5,
        migrants_non_western: 3.3,
        income: 35000,
        latitude: 51.35,
        longitude: 5.3,
    },
    {
        title: "Eijsden-Margraten",
        province: "Limburg",
        cbs_code: "1903",
        population: 26010,
        area: 77.55,
        migrants: 13.3,
        migrants_western: 10.6,
        migrants_non_western: 2.8,
        income: 32100,
        latitude: 50.783333333333,
        longitude: 5.75,
    },
    {
        title: "Eindhoven",
        province: "Noord-Brabant",
        cbs_code: "0772",
        population: 243669,
        area: 87.66,
        migrants: 37.5,
        migrants_western: 15.4,
        migrants_non_western: 22.1,
        income: 28900,
        latitude: 51.433333333333,
        longitude: 5.4666666666667,
    },
    {
        title: "Elburg",
        province: "Gelderland",
        cbs_code: "0230",
        population: 24010,
        area: 63.82,
        migrants: 6.4,
        migrants_western: 3.5,
        migrants_non_western: 2.9,
        income: 28300,
        latitude: 52.45,
        longitude: 5.85,
    },
    {
        title: "Emmen",
        province: "Drenthe",
        cbs_code: "0114",
        population: 108852,
        area: 335.18,
        migrants: 12.4,
        migrants_western: 6.9,
        migrants_non_western: 5.4,
        income: 26200,
        latitude: 52.783333333333,
        longitude: 6.8833333333333,
    },
    {
        title: "Enkhuizen",
        province: "Noord-Holland",
        cbs_code: "0388",
        population: 18873,
        area: 12.68,
        migrants: 16.9,
        migrants_western: 9.5,
        migrants_non_western: 7.4,
        income: 28200,
        latitude: 52.7,
        longitude: 5.3,
    },
    {
        title: "Enschede",
        province: "Overijssel",
        cbs_code: "0153",
        population: 161194,
        area: 140.83,
        migrants: 30.3,
        migrants_western: 12.8,
        migrants_non_western: 17.5,
        income: 25400,
        latitude: 52.216666666667,
        longitude: 6.8833333333333,
    },
    {
        title: "Epe",
        province: "Gelderland",
        cbs_code: "0232",
        population: 33246,
        area: 156.07,
        migrants: 11.8,
        migrants_western: 6.2,
        migrants_non_western: 5.7,
        income: 29600,
        latitude: 52.333333333333,
        longitude: 5.9166666666667,
    },
    {
        title: "Ermelo",
        province: "Gelderland",
        cbs_code: "0233",
        population: 27516,
        area: 85.63,
        migrants: 11.2,
        migrants_western: 6,
        migrants_non_western: 5.2,
        income: 31100,
        latitude: 52.283333333333,
        longitude: 5.6666666666667,
    },
    {
        title: "Etten-Leur",
        province: "Noord-Brabant",
        cbs_code: "0777",
        population: 44652,
        area: 55.3,
        migrants: 19.5,
        migrants_western: 9.6,
        migrants_non_western: 9.9,
        income: 29400,
        latitude: 51.566666666667,
        longitude: 4.6333333333333,
    },
    {
        title: "Geertruidenberg",
        province: "Noord-Brabant",
        cbs_code: "0779",
        population: 22104,
        area: 26.63,
        migrants: 10.7,
        migrants_western: 6.4,
        migrants_non_western: 4.3,
        income: 29700,
        latitude: 51.7,
        longitude: 4.85,
    },
    {
        title: "Geldrop-Mierlo",
        province: "Noord-Brabant",
        cbs_code: "1771",
        population: 40484,
        area: 31.01,
        migrants: 17.9,
        migrants_western: 9.6,
        migrants_non_western: 8.3,
        income: 30000,
        latitude: 51.416666666667,
        longitude: 5.55,
    },
    {
        title: "Gemert-Bakel",
        province: "Noord-Brabant",
        cbs_code: "1652",
        population: 31364,
        area: 122.14,
        migrants: 11.3,
        migrants_western: 7.8,
        migrants_non_western: 3.5,
        income: 29300,
        latitude: 51.516666666667,
        longitude: 5.7166666666667,
    },
    {
        title: "Gennep",
        province: "Limburg",
        cbs_code: "0907",
        population: 17771,
        area: 47.62,
        migrants: 14.1,
        migrants_western: 9.7,
        migrants_non_western: 4.4,
        income: 28600,
        latitude: 51.683333333333,
        longitude: 5.9666666666667,
    },
    {
        title: "Gilze en Rijen",
        province: "Noord-Brabant",
        cbs_code: "0784",
        population: 26939,
        area: 65.38,
        migrants: 17,
        migrants_western: 7.9,
        migrants_non_western: 9.1,
        income: 29400,
        latitude: 51.55,
        longitude: 4.9166666666667,
    },
    {
        title: "Goeree-Overflakkee",
        province: "Zuid-Holland",
        cbs_code: "1924",
        population: 51579,
        area: 260.48,
        migrants: 7,
        migrants_western: 4.2,
        migrants_non_western: 2.8,
        income: 30300,
        latitude: 51.75,
        longitude: 4.1166666666667,
    },
    {
        title: "Goes",
        province: "Zeeland",
        cbs_code: "0664",
        population: 39432,
        area: 92.58,
        migrants: 16.4,
        migrants_western: 8.6,
        migrants_non_western: 7.8,
        income: 28900,
        latitude: 51.516666666667,
        longitude: 3.8333333333333,
    },
    {
        title: "Goirle",
        province: "Noord-Brabant",
        cbs_code: "0785",
        population: 24204,
        area: 42.99,
        migrants: 11.6,
        migrants_western: 6.8,
        migrants_non_western: 4.8,
        income: 31200,
        latitude: 51.516666666667,
        longitude: 5.0666666666667,
    },
    {
        title: "Gooise Meren",
        province: "Noord-Holland",
        cbs_code: "1942",
        population: 60423,
        area: 41.59,
        migrants: 21.7,
        migrants_western: 12.5,
        migrants_non_western: 9.2,
        income: 38200,
        latitude: 52.266666666667,
        longitude: 5.1666666666667,
    },
    {
        title: "Gorinchem",
        province: "Zuid-Holland",
        cbs_code: "0512",
        population: 38506,
        area: 18.83,
        migrants: 26.6,
        migrants_western: 10,
        migrants_non_western: 16.6,
        income: 28800,
        latitude: 51.833333333333,
        longitude: 4.9666666666667,
    },
    {
        title: "Gouda",
        province: "Zuid-Holland",
        cbs_code: "0513",
        population: 75298,
        area: 16.5,
        migrants: 26.3,
        migrants_western: 9.2,
        migrants_non_western: 17.1,
        income: 28900,
        latitude: 52.016666666667,
        longitude: 4.7,
    },
    {
        title: "Groningen",
        province: "Groningen",
        cbs_code: "0014",
        population: 238016,
        area: 185.6,
        migrants: 24.7,
        migrants_western: 12.6,
        migrants_non_western: 12.1,
        income: 25700,
        latitude: 53.216666666667,
        longitude: 6.5666666666667,
    },
    {
        title: "Gulpen-Wittem",
        province: "Limburg",
        cbs_code: "1729",
        population: 14177,
        area: 73.18,
        migrants: 14.1,
        migrants_western: 11.7,
        migrants_non_western: 2.4,
        income: 29300,
        latitude: 50.8,
        longitude: 5.8833333333333,
    },
    {
        title: "Haaksbergen",
        province: "Overijssel",
        cbs_code: "0158",
        population: 24494,
        area: 104.82,
        migrants: 12.5,
        migrants_western: 6.7,
        migrants_non_western: 5.8,
        income: 29200,
        latitude: 52.15,
        longitude: 6.7333333333333,
    },
    {
        title: "Haarlem",
        province: "Noord-Holland",
        cbs_code: "0392",
        population: 165650,
        area: 29.17,
        migrants: 30.8,
        migrants_western: 14.3,
        migrants_non_western: 16.5,
        income: 30300,
        latitude: 52.383333333333,
        longitude: 4.6333333333333,
    },
    {
        title: "Haarlemmermeer",
        province: "Noord-Holland",
        cbs_code: "0394",
        population: 162344,
        area: 197.48,
        migrants: 28.5,
        migrants_western: 11.7,
        migrants_non_western: 16.8,
        income: 32600,
        latitude: 52.316666666667,
        longitude: 4.6833333333333,
    },
    {
        title: "Halderberge",
        province: "Noord-Brabant",
        cbs_code: "1655",
        population: 31020,
        area: 74.47,
        migrants: 15.9,
        migrants_western: 7.9,
        migrants_non_western: 8,
        income: 29500,
        latitude: 51.583333333333,
        longitude: 4.5166666666667,
    },
    {
        title: "Hardenberg",
        province: "Overijssel",
        cbs_code: "0160",
        population: 62579,
        area: 312.28,
        migrants: 6.9,
        migrants_western: 3.8,
        migrants_non_western: 3.1,
        income: 27900,
        latitude: 52.566666666667,
        longitude: 6.6,
    },
    {
        title: "Harderwijk",
        province: "Gelderland",
        cbs_code: "0243",
        population: 48946,
        area: 38.89,
        migrants: 19.2,
        migrants_western: 6.9,
        migrants_non_western: 12.2,
        income: 28700,
        latitude: 52.35,
        longitude: 5.6166666666667,
    },
    {
        title: "Hardinxveld-Giessendam",
        province: "Zuid-Holland",
        cbs_code: "0523",
        population: 18678,
        area: 16.91,
        migrants: 7.5,
        migrants_western: 3.6,
        migrants_non_western: 3.9,
        income: 29400,
        latitude: 51.833333333333,
        longitude: 4.8333333333333,
    },
    {
        title: "Harlingen",
        province: "Friesland",
        cbs_code: "0072",
        population: 16192,
        area: 24.96,
        migrants: 12.4,
        migrants_western: 7.6,
        migrants_non_western: 4.8,
        income: 26000,
        latitude: 53.3,
        longitude: 5.6833333333333,
    },
    {
        title: "Hattem",
        province: "Gelderland",
        cbs_code: "0244",
        population: 12569,
        area: 23.08,
        migrants: 8,
        migrants_western: 4.5,
        migrants_non_western: 3.5,
        income: 31500,
        latitude: 52.483333333333,
        longitude: 6.0666666666667,
    },
    {
        title: "Heemskerk",
        province: "Noord-Holland",
        cbs_code: "0396",
        population: 39451,
        area: 27.34,
        migrants: 20.9,
        migrants_western: 9.5,
        migrants_non_western: 11.3,
        income: 29800,
        latitude: 52.5,
        longitude: 4.6666666666667,
    },
    {
        title: "Heemstede",
        province: "Noord-Holland",
        cbs_code: "0397",
        population: 27766,
        area: 9.18,
        migrants: 20.2,
        migrants_western: 13.1,
        migrants_non_western: 7.1,
        income: 40500,
        latitude: 52.35,
        longitude: 4.6166666666667,
    },
    {
        title: "Heerde",
        province: "Gelderland",
        cbs_code: "0246",
        population: 19227,
        area: 78.74,
        migrants: 6.1,
        migrants_western: 3.4,
        migrants_non_western: 2.8,
        income: 0,
        latitude: 52.4,
        longitude: 6.05,
    },
    {
        title: "Heerenveen",
        province: "Friesland",
        cbs_code: "0074",
        population: 51654,
        area: 190.09,
        migrants: 12.3,
        migrants_western: 5.5,
        migrants_non_western: 6.8,
        income: 0,
        latitude: 52.966666666667,
        longitude: 6.05,
    },
    {
        title: "Heerlen",
        province: "Limburg",
        cbs_code: "0917",
        population: 87116,
        area: 44.94,
        migrants: 29.1,
        migrants_western: 18.4,
        migrants_non_western: 10.7,
        income: 0,
        latitude: 50.883333333333,
        longitude: 5.9666666666667,
    },
    {
        title: "Heeze-Leende",
        province: "Noord-Brabant",
        cbs_code: "1658",
        population: 16640,
        area: 104.02,
        migrants: 11.1,
        migrants_western: 7.6,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 51.366666666667,
        longitude: 5.55,
    },
    {
        title: "Heiloo",
        province: "Noord-Holland",
        cbs_code: "0399",
        population: 24280,
        area: 18.7,
        migrants: 13.1,
        migrants_western: 8.1,
        migrants_non_western: 5,
        income: 0,
        latitude: 52.6,
        longitude: 4.7,
    },
    {
        title: "Hellendoorn",
        province: "Overijssel",
        cbs_code: "0163",
        population: 36245,
        area: 137.91,
        migrants: 7,
        migrants_western: 4.1,
        migrants_non_western: 2.9,
        income: 0,
        latitude: 52.35,
        longitude: 6.45,
    },
    {
        title: "Helmond",
        province: "Noord-Brabant",
        cbs_code: "0794",
        population: 94967,
        area: 53.18,
        migrants: 27.8,
        migrants_western: 14.2,
        migrants_non_western: 13.6,
        income: 0,
        latitude: 51.483333333333,
        longitude: 5.65,
    },
    {
        title: "Hendrik-Ido-Ambacht",
        province: "Zuid-Holland",
        cbs_code: "0531",
        population: 32176,
        area: 10.61,
        migrants: 14.8,
        migrants_western: 6.3,
        migrants_non_western: 8.5,
        income: 0,
        latitude: 51.85,
        longitude: 4.6333333333333,
    },
    {
        title: "Hengelo",
        province: "Overijssel",
        cbs_code: "0164",
        population: 82384,
        area: 60.84,
        migrants: 23.3,
        migrants_western: 9.7,
        migrants_non_western: 13.6,
        income: 0,
        latitude: 52.266666666667,
        longitude: 6.7833333333333,
    },
    {
        title: "'s-Hertogenbosch",
        province: "Noord-Brabant",
        cbs_code: "0796",
        population: 158952,
        area: 109.99,
        migrants: 20.9,
        migrants_western: 9.1,
        migrants_non_western: 11.8,
        income: 0,
        latitude: 51.683333333333,
        longitude: 5.3,
    },
    {
        title: "Het Hogeland",
        province: "Groningen",
        cbs_code: "1966",
        population: 48255,
        area: 482.75,
        migrants: 7.7,
        migrants_western: 4.4,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 53.4,
        longitude: 6.6666666666667,
    },
    {
        title: "Heumen",
        province: "Gelderland",
        cbs_code: "0252",
        population: 16779,
        area: 39.76,
        migrants: 14,
        migrants_western: 9.1,
        migrants_non_western: 4.8,
        income: 0,
        latitude: 51.783333333333,
        longitude: 5.8666666666667,
    },
    {
        title: "Heusden",
        province: "Noord-Brabant",
        cbs_code: "0797",
        population: 45854,
        area: 78.88,
        migrants: 14.5,
        migrants_western: 7.9,
        migrants_non_western: 6.6,
        income: 0,
        latitude: 51.7,
        longitude: 5.1666666666667,
    },
    {
        title: "Hillegom",
        province: "Zuid-Holland",
        cbs_code: "0534",
        population: 22518,
        area: 12.87,
        migrants: 18.5,
        migrants_western: 11.6,
        migrants_non_western: 6.9,
        income: 0,
        latitude: 52.3,
        longitude: 4.5833333333333,
    },
    {
        title: "Hilvarenbeek",
        province: "Noord-Brabant",
        cbs_code: "0798",
        population: 15956,
        area: 94.85,
        migrants: 8.1,
        migrants_western: 5.6,
        migrants_non_western: 2.5,
        income: 0,
        latitude: 51.483333333333,
        longitude: 5.1333333333333,
    },
    {
        title: "Hilversum",
        province: "Noord-Holland",
        cbs_code: "0402",
        population: 93396,
        area: 45.61,
        migrants: 29.1,
        migrants_western: 14,
        migrants_non_western: 15.1,
        income: 0,
        latitude: 52.216666666667,
        longitude: 5.1833333333333,
    },
    {
        title: "Hoeksche Waard",
        province: "Zuid-Holland",
        cbs_code: "1963",
        population: 89846,
        area: 268.93,
        migrants: 9.4,
        migrants_western: 5.4,
        migrants_non_western: 4.1,
        income: 0,
        latitude: 51.766666666667,
        longitude: 4.4666666666667,
    },
    {
        title: "Hof van Twente",
        province: "Overijssel",
        cbs_code: "1735",
        population: 35450,
        area: 212.56,
        migrants: 9.3,
        migrants_western: 5.7,
        migrants_non_western: 3.7,
        income: 0,
        latitude: 52.25,
        longitude: 6.7,
    },
    {
        title: "Hollands Kroon",
        province: "Noord-Holland",
        cbs_code: "1911",
        population: 49387,
        area: 357.34,
        migrants: 12,
        migrants_western: 8.5,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 52.866666666667,
        longitude: 4.8,
    },
    {
        title: "Hoogeveen",
        province: "Drenthe",
        cbs_code: "0118",
        population: 56437,
        area: 127.54,
        migrants: 11.1,
        migrants_western: 5.8,
        migrants_non_western: 5.3,
        income: 0,
        latitude: 52.066666666667,
        longitude: 6.4666666666667,
    },
    {
        title: "Hoorn",
        province: "Noord-Holland",
        cbs_code: "0405",
        population: 75240,
        area: 20.38,
        migrants: 24.4,
        migrants_western: 9.5,
        migrants_non_western: 14.9,
        income: 0,
        latitude: 52.633333333333,
        longitude: 5.05,
    },
    {
        title: "Horst aan de Maas",
        province: "Limburg",
        cbs_code: "1507",
        population: 43642,
        area: 188.73,
        migrants: 12.1,
        migrants_western: 9.1,
        migrants_non_western: 3,
        income: 0,
        latitude: 51.433333333333,
        longitude: 6.1,
    },
    {
        title: "Houten",
        province: "Utrecht",
        cbs_code: "0321",
        population: 50593,
        area: 54.94,
        migrants: 16,
        migrants_western: 7.4,
        migrants_non_western: 8.6,
        income: 0,
        latitude: 52.033333333333,
        longitude: 5.1666666666667,
    },
    {
        title: "Huizen",
        province: "Noord-Holland",
        cbs_code: "0406",
        population: 41215,
        area: 15.81,
        migrants: 21.2,
        migrants_western: 10,
        migrants_non_western: 11.2,
        income: 0,
        latitude: 52.283333333333,
        longitude: 5.25,
    },
    {
        title: "Hulst",
        province: "Zeeland",
        cbs_code: "0677",
        population: 27586,
        area: 201.71,
        migrants: 28.7,
        migrants_western: 25.4,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 51.316666666667,
        longitude: 4.05,
    },
    {
        title: "IJsselstein",
        province: "Utrecht",
        cbs_code: "0353",
        population: 33501,
        area: 21.07,
        migrants: 20.8,
        migrants_western: 7.3,
        migrants_non_western: 13.5,
        income: 0,
        latitude: 52.016666666667,
        longitude: 5.05,
    },
    {
        title: "Kaag en Braassem",
        province: "Zuid-Holland",
        cbs_code: "1884",
        population: 28590,
        area: 63.24,
        migrants: 11.9,
        migrants_western: 6.7,
        migrants_non_western: 5.2,
        income: 0,
        latitude: 52.2,
        longitude: 4.6166666666667,
    },
    {
        title: "Kampen",
        province: "Overijssel",
        cbs_code: "0166",
        population: 55650,
        area: 142.18,
        migrants: 10.2,
        migrants_western: 4.3,
        migrants_non_western: 6,
        income: 0,
        latitude: 52.55,
        longitude: 5.9,
    },
    {
        title: "Kapelle",
        province: "Zeeland",
        cbs_code: "0678",
        population: 13029,
        area: 37.13,
        migrants: 9.8,
        migrants_western: 6.2,
        migrants_non_western: 3.6,
        income: 0,
        latitude: 51.5,
        longitude: 3.95,
    },
    {
        title: "Katwijk",
        province: "Zuid-Holland",
        cbs_code: "0537",
        population: 66635,
        area: 24.75,
        migrants: 11.5,
        migrants_western: 5.8,
        migrants_non_western: 5.7,
        income: 0,
        latitude: 52.2,
        longitude: 4.4,
    },
    {
        title: "Kerkrade",
        province: "Limburg",
        cbs_code: "0928",
        population: 45642,
        area: 21.91,
        migrants: 32.1,
        migrants_western: 25.2,
        migrants_non_western: 6.9,
        income: 0,
        latitude: 50.85,
        longitude: 6.05,
    },
    {
        title: "Koggenland",
        province: "Noord-Holland",
        cbs_code: "1598",
        population: 23517,
        area: 80.32,
        migrants: 8.7,
        migrants_western: 5,
        migrants_non_western: 3.7,
        income: 0,
        latitude: 52.633333333333,
        longitude: 4.95,
    },
    {
        title: "Krimpen aan den IJssel",
        province: "Zuid-Holland",
        cbs_code: "0542",
        population: 29501,
        area: 7.69,
        migrants: 16.6,
        migrants_western: 7.5,
        migrants_non_western: 9.1,
        income: 0,
        latitude: 51.916666666667,
        longitude: 4.6,
    },
    {
        title: "Krimpenerwaard",
        province: "Zuid-Holland",
        cbs_code: "1931",
        population: 57729,
        area: 148.4,
        migrants: 11.4,
        migrants_western: 5.4,
        migrants_non_western: 6,
        income: 0,
        latitude: 51.983333333333,
        longitude: 4.7833333333333,
    },
    {
        title: "Laarbeek",
        province: "Noord-Brabant",
        cbs_code: "1659",
        population: 23253,
        area: 55.35,
        migrants: 9.4,
        migrants_western: 6.6,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 51.516666666667,
        longitude: 5.6166666666667,
    },
    {
        title: "Land van Cuijk",
        province: "Noord-Brabant",
        cbs_code: "1982",
        population: 91489,
        area: 351.87,
        migrants: 12.3,
        migrants_western: 6.9,
        migrants_non_western: 5.4,
        income: 0,
        latitude: 51.666666666667,
        longitude: 5.8666666666667,
    },
    {
        title: "Landgraaf",
        province: "Limburg",
        cbs_code: "0882",
        population: 37146,
        area: 24.58,
        migrants: 23.1,
        migrants_western: 18.4,
        migrants_non_western: 4.7,
        income: 0,
        latitude: 50.9,
        longitude: 6.0166666666667,
    },
    {
        title: "Landsmeer",
        province: "Noord-Holland",
        cbs_code: "0415",
        population: 11688,
        area: 22.53,
        migrants: 18.1,
        migrants_western: 9.2,
        migrants_non_western: 8.9,
        income: 0,
        latitude: 52.416666666667,
        longitude: 4.9,
    },
    {
        title: "Lansingerland",
        province: "Zuid-Holland",
        cbs_code: "1621",
        population: 64737,
        area: 53.42,
        migrants: 18.9,
        migrants_western: 7.5,
        migrants_non_western: 11.3,
        income: 0,
        latitude: 52,
        longitude: 4.5,
    },
    {
        title: "Laren",
        province: "Noord-Holland",
        cbs_code: "0417",
        population: 11724,
        area: 12.41,
        migrants: 20.6,
        migrants_western: 14.2,
        migrants_non_western: 6.4,
        income: 0,
        latitude: 52.25,
        longitude: 5.2166666666667,
    },
    {
        title: "Leeuwarden",
        province: "Friesland",
        cbs_code: "0080",
        population: 127064,
        area: 238.38,
        migrants: 17.7,
        migrants_western: 7.9,
        migrants_non_western: 9.9,
        income: 0,
        latitude: 53.2,
        longitude: 5.7833333333333,
    },
    {
        title: "Leiden",
        province: "Zuid-Holland",
        cbs_code: "0546",
        population: 127046,
        area: 21.91,
        migrants: 32.2,
        migrants_western: 15.2,
        migrants_non_western: 17,
        income: 0,
        latitude: 52.15,
        longitude: 4.4833333333333,
    },
    {
        title: "Leiderdorp",
        province: "Zuid-Holland",
        cbs_code: "0547",
        population: 27723,
        area: 11.58,
        migrants: 22.1,
        migrants_western: 9.9,
        migrants_non_western: 12.2,
        income: 0,
        latitude: 52.15,
        longitude: 4.5333333333333,
    },
    {
        title: "Leidschendam-Voorburg",
        province: "Zuid-Holland",
        cbs_code: "1916",
        population: 77798,
        area: 32.55,
        migrants: 32.4,
        migrants_western: 14.8,
        migrants_non_western: 17.6,
        income: 0,
        latitude: 52.083333333333,
        longitude: 4.4,
    },
    {
        title: "Lelystad",
        province: "Flevoland",
        cbs_code: "0995",
        population: 83192,
        area: 230.32,
        migrants: 32.7,
        migrants_western: 10.9,
        migrants_non_western: 21.8,
        income: 0,
        latitude: 52.5,
        longitude: 5.4666666666667,
    },
    {
        title: "Leudal",
        province: "Limburg",
        cbs_code: "1640",
        population: 36106,
        area: 162.73,
        migrants: 13,
        migrants_western: 9.9,
        migrants_non_western: 3.1,
        income: 0,
        latitude: 51.25,
        longitude: 5.9,
    },
    {
        title: "Leusden",
        province: "Utrecht",
        cbs_code: "0327",
        population: 31514,
        area: 58.54,
        migrants: 14.2,
        migrants_western: 8.1,
        migrants_non_western: 6.2,
        income: 0,
        latitude: 52.133333333333,
        longitude: 5.4166666666667,
    },
    {
        title: "Lingewaard",
        province: "Gelderland",
        cbs_code: "1705",
        population: 47241,
        area: 62,
        migrants: 10.7,
        migrants_western: 6.8,
        migrants_non_western: 3.9,
        income: 0,
        latitude: 51.9,
        longitude: 5.9,
    },
    {
        title: "Lisse",
        province: "Zuid-Holland",
        cbs_code: "0553",
        population: 23373,
        area: 15.69,
        migrants: 14.1,
        migrants_western: 8.8,
        migrants_non_western: 5.2,
        income: 0,
        latitude: 52.25,
        longitude: 4.55,
    },
    {
        title: "Lochem",
        province: "Gelderland",
        cbs_code: "0262",
        population: 34302,
        area: 213.03,
        migrants: 10.8,
        migrants_western: 6,
        migrants_non_western: 4.9,
        income: 0,
        latitude: 52.15,
        longitude: 6.4,
    },
    {
        title: "Loon op Zand",
        province: "Noord-Brabant",
        cbs_code: "0809",
        population: 23802,
        area: 49.94,
        migrants: 11.4,
        migrants_western: 7.1,
        migrants_non_western: 4.3,
        income: 0,
        latitude: 51.616666666667,
        longitude: 5.0666666666667,
    },
    {
        title: "Lopik",
        province: "Utrecht",
        cbs_code: "0331",
        population: 14698,
        area: 75.57,
        migrants: 9.4,
        migrants_western: 4.3,
        migrants_non_western: 5.1,
        income: 0,
        longitude: 4.95,
        latitude: 51.983,
    },
    {
        title: "Losser",
        province: "Overijssel",
        cbs_code: "0168",
        population: 23386,
        area: 98.76,
        migrants: 14.6,
        migrants_western: 10.9,
        migrants_non_western: 3.7,
        income: 0,
        latitude: 52.25,
        longitude: 7,
    },
    {
        title: "Maasdriel",
        province: "Gelderland",
        cbs_code: "0263",
        population: 26041,
        area: 66.11,
        migrants: 13.1,
        migrants_western: 9.4,
        migrants_non_western: 3.7,
        income: 0,
        latitude: 51.766666666667,
        longitude: 5.3333333333333,
    },
    {
        title: "Maasgouw",
        province: "Limburg",
        cbs_code: "1641",
        population: 24358,
        area: 45.71,
        migrants: 14.7,
        migrants_western: 11.6,
        migrants_non_western: 3,
        income: 0,
        latitude: 51.15,
        longitude: 5.8833333333333,
    },
    {
        title: "Maashorst",
        province: "Noord-Brabant",
        cbs_code: "1991",
        population: 58934,
        area: 138.24,
        migrants: 0,
        migrants_western: 0,
        migrants_non_western: 0,
        income: 0,
        latitude: 51.683333333333,
        longitude: 5.6166666666667,
    },
    {
        title: "Maassluis",
        province: "Zuid-Holland",
        cbs_code: "0556",
        population: 35346,
        area: 8.48,
        migrants: 28.6,
        migrants_western: 9.1,
        migrants_non_western: 19.5,
        income: 0,
        latitude: 51.933333333333,
        longitude: 4.25,
    },
    {
        title: "Maastricht",
        province: "Limburg",
        cbs_code: "0935",
        population: 122692,
        area: 55.99,
        migrants: 33.1,
        migrants_western: 21.6,
        migrants_non_western: 11.6,
        income: 0,
        latitude: 50.85,
        longitude: 5.6833333333333,
    },
    {
        title: "Medemblik",
        province: "Noord-Holland",
        cbs_code: "0420",
        population: 46071,
        area: 121.42,
        migrants: 13.2,
        migrants_western: 8.1,
        migrants_non_western: 5.1,
        income: 0,
        latitude: 52.766666666667,
        longitude: 5.1,
    },
    {
        title: "Meerssen",
        province: "Limburg",
        cbs_code: "0938",
        population: 18594,
        area: 26.96,
        migrants: 12.9,
        migrants_western: 9.6,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 50.883333333333,
        longitude: 5.75,
    },
    {
        title: "Meierijstad",
        province: "Noord-Brabant",
        cbs_code: "1948",
        population: 83706,
        area: 184.09,
        migrants: 14.5,
        migrants_western: 7.8,
        migrants_non_western: 6.7,
        income: 0,
        latitude: 51.583333333333,
        longitude: 5.4833333333333,
    },
    {
        title: "Meppel",
        province: "Drenthe",
        cbs_code: "0119",
        population: 35454,
        area: 55.53,
        migrants: 12,
        migrants_western: 5.8,
        migrants_non_western: 6.2,
        income: 0,
        latitude: 52.7,
        longitude: 6.1833333333333,
    },
    {
        title: "Middelburg",
        province: "Zeeland",
        cbs_code: "0687",
        population: 49919,
        area: 48.42,
        migrants: 19.4,
        migrants_western: 10.1,
        migrants_non_western: 9.3,
        income: 0,
        latitude: 51.5,
        longitude: 3.6166666666667,
    },
    {
        title: "Midden-Delfland",
        province: "Zuid-Holland",
        cbs_code: "1842",
        population: 19449,
        area: 47.19,
        migrants: 12.6,
        migrants_western: 6.4,
        migrants_non_western: 6.3,
        income: 0,
        latitude: 51.966666666667,
        longitude: 4.3,
    },
    {
        title: "Midden-Drenthe",
        province: "Drenthe",
        cbs_code: "1731",
        population: 34011,
        area: 340.51,
        migrants: 7.3,
        migrants_western: 4.6,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 52.85,
        longitude: 6.5,
    },
    {
        title: "Midden-Groningen",
        province: "Groningen",
        cbs_code: "1952",
        population: 61573,
        area: 280,
        migrants: 14.8,
        migrants_western: 6.4,
        migrants_non_western: 8.5,
        income: 0,
        latitude: 53.15,
        longitude: 6.75,
    },
    {
        title: "Moerdijk",
        province: "Noord-Brabant",
        cbs_code: "1709",
        population: 37717,
        area: 159.15,
        migrants: 12.4,
        migrants_western: 7.3,
        migrants_non_western: 5.1,
        income: 0,
        latitude: 51.65,
        longitude: 4.5166666666667,
    },
    {
        title: "Molenlanden",
        province: "Zuid-Holland",
        cbs_code: "1978",
        population: 45143,
        area: 181.73,
        migrants: 7,
        migrants_western: 4,
        migrants_non_western: 2.9,
        income: 0,
        latitude: 51.85,
        longitude: 4.8,
    },
    {
        title: "Montferland",
        province: "Gelderland",
        cbs_code: "1955",
        population: 36891,
        area: 105.7,
        migrants: 12.4,
        migrants_western: 8.5,
        migrants_non_western: 4,
        income: 0,
        latitude: 51.933333333333,
        longitude: 6.15,
    },
    {
        title: "Montfoort",
        province: "Utrecht",
        cbs_code: "0335",
        population: 13911,
        area: 37.57,
        migrants: 10.5,
        migrants_western: 9.9,
        migrants_non_western: 5.5,
        income: 0,
        latitude: 52.033333333333,
        longitude: 4.95,
    },
    {
        title: "Mook en Middelaar",
        province: "Limburg",
        cbs_code: "0944",
        population: 8024,
        area: 17.38,
        migrants: 14.1,
        migrants_western: 9.9,
        migrants_non_western: 4.1,
        income: 0,
        latitude: 51.75,
        longitude: 5.8666666666667,
    },
    {
        title: "Neder-Betuwe",
        province: "Gelderland",
        cbs_code: "1740",
        population: 25457,
        area: 59.98,
        migrants: 8.6,
        migrants_western: 5.9,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 51.933333333333,
        longitude: 5.6333333333333,
    },
    {
        title: "Nederweert",
        province: "Limburg",
        cbs_code: "0946",
        population: 17476,
        area: 99.98,
        migrants: 9.5,
        migrants_western: 6.7,
        migrants_non_western: 2.8,
        income: 0,
        latitude: 51.283333333333,
        longitude: 5.75,
    },
    {
        title: "Nieuwegein",
        province: "Utrecht",
        cbs_code: "0356",
        population: 65540,
        area: 23.51,
        migrants: 27.9,
        migrants_western: 10.4,
        migrants_non_western: 17.5,
        income: 0,
        latitude: 52.016666666667,
        longitude: 5.0833333333333,
    },
    {
        title: "Nieuwkoop",
        province: "Zuid-Holland",
        cbs_code: "0569",
        population: 29434,
        area: 78.05,
        migrants: 11.2,
        migrants_western: 6.5,
        migrants_non_western: 4.7,
        income: 0,
        latitude: 52.15,
        longitude: 4.8,
    },
    {
        title: "Nijkerk",
        province: "Gelderland",
        cbs_code: "0267",
        population: 45029,
        area: 69.34,
        migrants: 12.1,
        migrants_western: 5.4,
        migrants_non_western: 6.7,
        income: 0,
        latitude: 52.2,
        longitude: 5.4833333333333,
    },
    {
        title: "Nijmegen",
        province: "Gelderland",
        cbs_code: "0268",
        population: 182399,
        area: 53.09,
        migrants: 26.5,
        migrants_western: 12.1,
        migrants_non_western: 14.4,
        income: 0,
        latitude: 51.833333333333,
        longitude: 5.8666666666667,
    },
    {
        title: "Nissewaard",
        province: "Zuid-Holland",
        cbs_code: "1930",
        population: 86857,
        area: 73.58,
        migrants: 25.3,
        migrants_western: 9.5,
        migrants_non_western: 15.8,
        income: 0,
        latitude: 51.833333333333,
        longitude: 4.3,
    },
    {
        title: "Noardeast-Fryslân",
        province: "Friesland",
        cbs_code: "1970",
        population: 45818,
        area: 377.83,
        migrants: 5.8,
        migrants_western: 3.2,
        migrants_non_western: 2.6,
        income: 0,
        latitude: 53.316666666667,
        longitude: 5.9833333333333,
    },
    {
        title: "Noord-Beveland",
        province: "Zeeland",
        cbs_code: "1695",
        population: 7881,
        area: 85.96,
        migrants: 13.4,
        migrants_western: 10.4,
        migrants_non_western: 3,
        income: 0,
        latitude: 51.583333333333,
        longitude: 3.7333333333333,
    },
    {
        title: "Noordenveld",
        province: "Drenthe",
        cbs_code: "1699",
        population: 31612,
        area: 199.41,
        migrants: 7.9,
        migrants_western: 4.6,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 53.133333333333,
        longitude: 6.4166666666667,
    },
    {
        title: "Noordoostpolder",
        province: "Flevoland",
        cbs_code: "0171",
        population: 49909,
        area: 458.17,
        migrants: 15.8,
        migrants_western: 8.1,
        migrants_non_western: 7.8,
        income: 0,
        latitude: 52.716666666667,
        longitude: 5.7,
    },
    {
        title: "Noordwijk",
        province: "Zuid-Holland",
        cbs_code: "0575",
        population: 45258,
        area: 58.37,
        migrants: 17.9,
        migrants_western: 12.5,
        migrants_non_western: 5.4,
        income: 0,
        latitude: 52.25,
        longitude: 4.4333333333333,
    },
    {
        title: "Nuenen, Gerwen en Nederwetten",
        province: "Noord-Brabant",
        cbs_code: "0820",
        population: 24046,
        area: 33.71,
        migrants: 13.5,
        migrants_western: 9.1,
        migrants_non_western: 4.4,
        income: 0,
        latitude: 51.483333333333,
        longitude: 5.5166666666667,
    },
    {
        title: "Nunspeet",
        province: "Gelderland",
        cbs_code: "0302",
        population: 28749,
        area: 128.74,
        migrants: 7.1,
        migrants_western: 3.6,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 52.333333333333,
        longitude: 5.7833333333333,
    },
    {
        title: "Oegstgeest",
        province: "Zuid-Holland",
        cbs_code: "0579",
        population: 25795,
        area: 7.31,
        migrants: 24.8,
        migrants_western: 15.7,
        migrants_non_western: 9.1,
        income: 0,
        latitude: 52.183333333333,
        longitude: 4.4666666666667,
    },
    {
        title: "Oirschot",
        province: "Noord-Brabant",
        cbs_code: "0823",
        population: 19197,
        area: 101.78,
        migrants: 8.1,
        migrants_western: 5.2,
        migrants_non_western: 3,
        income: 0,
        latitude: 51.5,
        longitude: 5.3,
    },
    {
        title: "Oisterwijk",
        province: "Noord-Brabant",
        cbs_code: "0824",
        population: 32963,
        area: 63.84,
        migrants: 11.5,
        migrants_western: 6.6,
        migrants_non_western: 4.9,
        income: 0,
        latitude: 51.566666666667,
        longitude: 5.2,
    },
    {
        title: "Oldambt",
        province: "Groningen",
        cbs_code: "1895",
        population: 39073,
        area: 226.66,
        migrants: 11.6,
        migrants_western: 7.2,
        migrants_non_western: 4.1,
        income: 0,
        latitude: 53.2,
        longitude: 7.0833333333333,
    },
    {
        title: "Oldebroek",
        province: "Gelderland",
        cbs_code: "0269",
        population: 24263,
        area: 97.65,
        migrants: 6,
        migrants_western: 3.4,
        migrants_non_western: 2.6,
        income: 0,
        latitude: 52.466666666667,
        longitude: 5.9166666666667,
    },
    {
        title: "Oldenzaal",
        province: "Overijssel",
        cbs_code: "0173",
        population: 31921,
        area: 21.55,
        migrants: 15.8,
        migrants_western: 7.9,
        migrants_non_western: 7.9,
        income: 0,
        latitude: 52.3,
        longitude: 6.9166666666667,
    },
    {
        title: "Olst-Wijhe",
        province: "Overijssel",
        cbs_code: "1773",
        population: 18682,
        area: 113.84,
        migrants: 8.3,
        migrants_western: 4.6,
        migrants_non_western: 3.7,
        income: 0,
        latitude: 52.333333333333,
        longitude: 6.1,
    },
    {
        title: "Ommen",
        province: "Overijssel",
        cbs_code: "0175",
        population: 18955,
        area: 179.9,
        migrants: 7.8,
        migrants_western: 4.6,
        migrants_non_western: 3.2,
        income: 0,
        latitude: 52.516666666667,
        longitude: 6.4166666666667,
    },
    {
        title: "Oost Gelre",
        province: "Gelderland",
        cbs_code: "1586",
        population: 29863,
        area: 109.93,
        migrants: 8.4,
        migrants_western: 5.2,
        migrants_non_western: 3.1,
        income: 0,
        latitude: 51.983333333333,
        longitude: 6.5833333333333,
    },
    {
        title: "Oosterhout",
        province: "Noord-Brabant",
        cbs_code: "0826",
        population: 57466,
        area: 71.47,
        migrants: 20.7,
        migrants_western: 9.5,
        migrants_non_western: 11.2,
        income: 0,
        latitude: 51.65,
        longitude: 4.85,
    },
    {
        title: "Ooststellingwerf",
        province: "Friesland",
        cbs_code: "0085",
        population: 25854,
        area: 223.42,
        migrants: 7.2,
        migrants_western: 4.3,
        migrants_non_western: 2.9,
        income: 0,
        latitude: 53,
        longitude: 6.2833333333333,
    },
    {
        title: "Oostzaan",
        province: "Noord-Holland",
        cbs_code: "0431",
        population: 9719,
        area: 11.53,
        migrants: 14.5,
        migrants_western: 6.5,
        migrants_non_western: 8,
        income: 0,
        latitude: 52.433333333333,
        longitude: 4.8666666666667,
    },
    {
        title: "Opmeer",
        province: "Noord-Holland",
        cbs_code: "0432",
        population: 12158,
        area: 41.5,
        migrants: 9.4,
        migrants_western: 6.4,
        migrants_non_western: 3,
        income: 0,
        latitude: 52.7,
        longitude: 4.9666666666667,
    },
    {
        title: "Opsterland",
        province: "Friesland",
        cbs_code: "0086",
        population: 30064,
        area: 224.4,
        migrants: 6.4,
        migrants_western: 3.5,
        migrants_non_western: 2.9,
        income: 0,
        latitude: 53.083333333333,
        longitude: 6.1333333333333,
    },
    {
        title: "Oss",
        province: "Noord-Brabant",
        cbs_code: "0828",
        population: 94457,
        area: 163.16,
        migrants: 19.4,
        migrants_western: 9.4,
        migrants_non_western: 9.9,
        income: 0,
        latitude: 51.75,
        longitude: 5.5166666666667,
    },
    {
        title: "Oude IJsselstreek",
        province: "Gelderland",
        cbs_code: "1509",
        population: 39624,
        area: 136.15,
        migrants: 13.7,
        migrants_western: 7.2,
        migrants_non_western: 6.5,
        income: 0,
        latitude: 51.866666666667,
        longitude: 6.3833333333333,
    },
    {
        title: "Ouder-Amstel",
        province: "Noord-Holland",
        cbs_code: "0437",
        population: 14265,
        area: 24.08,
        migrants: 28.1,
        migrants_western: 13.3,
        migrants_non_western: 14.8,
        income: 0,
        latitude: 52.283333333333,
        longitude: 4.9,
    },
    {
        title: "Oudewater",
        province: "Utrecht",
        cbs_code: "0589",
        population: 10237,
        area: 38.9,
        migrants: 8.8,
        migrants_western: 4.5,
        migrants_non_western: 4.3,
        income: 0,
        latitude: 52.016666666667,
        longitude: 4.8666666666667,
    },
    {
        title: "Overbetuwe",
        province: "Gelderland",
        cbs_code: "1734",
        population: 48703,
        area: 109.19,
        migrants: 11.9,
        migrants_western: 7.1,
        migrants_non_western: 4.8,
        income: 0,
        latitude: 51.866666666667,
        longitude: 5.85,
    },
    {
        title: "Papendrecht",
        province: "Zuid-Holland",
        cbs_code: "0590",
        population: 32267,
        area: 9.41,
        migrants: 18.6,
        migrants_western: 8.4,
        migrants_non_western: 10.2,
        income: 0,
        latitude: 51.833333333333,
        longitude: 4.7,
    },
    {
        title: "Peel en Maas",
        province: "Limburg",
        cbs_code: "1894",
        population: 45314,
        area: 159.37,
        migrants: 11.3,
        migrants_western: 7.4,
        migrants_non_western: 3.9,
        income: 0,
        latitude: 51.316666666667,
        longitude: 5.9833333333333,
    },
    {
        title: "Pekela",
        province: "Groningen",
        cbs_code: "0765",
        population: 12429,
        area: 49.04,
        migrants: 9.1,
        migrants_western: 5.9,
        migrants_non_western: 3.1,
        income: 0,
        latitude: 53.1,
        longitude: 7,
    },
    {
        title: "Pijnacker-Nootdorp",
        province: "Zuid-Holland",
        cbs_code: "1926",
        population: 57688,
        area: 37.08,
        migrants: 22,
        migrants_western: 8.8,
        migrants_non_western: 13.1,
        income: 0,
        latitude: 52.016666666667,
        longitude: 4.4166666666667,
    },
    {
        title: "Purmerend",
        province: "Noord-Holland",
        cbs_code: "0439",
        population: 94129,
        area: 95.22,
        migrants: 27.6,
        migrants_western: 9.4,
        migrants_non_western: 18.1,
        income: 0,
        latitude: 52.5,
        longitude: 4.95,
    },
    {
        title: "Putten",
        province: "Gelderland",
        cbs_code: "0273",
        population: 24894,
        area: 85.22,
        migrants: 7.9,
        migrants_western: 4.4,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 52.233333333333,
        longitude: 5.5833333333333,
    },
    {
        title: "Raalte",
        province: "Overijssel",
        cbs_code: "0177",
        population: 38468,
        area: 170.99,
        migrants: 6.7,
        migrants_western: 3.4,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 52.4,
        longitude: 6.2833333333333,
    },
    {
        title: "Reimerswaal",
        province: "Zeeland",
        cbs_code: "0703",
        population: 23265,
        area: 101.8,
        migrants: 14.5,
        migrants_western: 10.1,
        migrants_non_western: 4.4,
        income: 0,
        latitude: 51.45,
        longitude: 4.0166666666667,
    },
    {
        title: "Renkum",
        province: "Gelderland",
        cbs_code: "0274",
        population: 31459,
        area: 45.95,
        migrants: 15.6,
        migrants_western: 9.7,
        migrants_non_western: 5.9,
        income: 0,
        latitude: 51.983333333333,
        longitude: 5.85,
    },
    {
        title: "Renswoude",
        province: "Utrecht",
        cbs_code: "0339",
        population: 5748,
        area: 18.4,
        migrants: 7.3,
        migrants_western: 4.2,
        migrants_non_western: 3.1,
        income: 0,
        latitude: 52.066666666667,
        longitude: 5.5333333333333,
    },
    {
        title: "Reusel-De Mierden",
        province: "Noord-Brabant",
        cbs_code: "1667",
        population: 13551,
        area: 77.88,
        migrants: 9.1,
        migrants_western: 6.4,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 51.35,
        longitude: 5.15,
    },
    {
        title: "Rheden",
        province: "Gelderland",
        cbs_code: "0275",
        population: 43522,
        area: 81.74,
        migrants: 17.2,
        migrants_western: 9.5,
        migrants_non_western: 7.7,
        income: 0,
        latitude: 52,
        longitude: 6.0333333333333,
    },
    {
        title: "Rhenen",
        province: "Utrecht",
        cbs_code: "0340",
        population: 20332,
        area: 42.08,
        migrants: 12,
        migrants_western: 5.9,
        migrants_non_western: 6.1,
        income: 0,
        latitude: 51.95,
        longitude: 5.5666666666667,
    },
    {
        title: "Ridderkerk",
        province: "Zuid-Holland",
        cbs_code: "0597",
        population: 47503,
        area: 23.72,
        migrants: 20,
        migrants_western: 7.8,
        migrants_non_western: 12.1,
        income: 0,
        latitude: 51.866666666667,
        longitude: 4.6,
    },
    {
        title: "Rijssen-Holten",
        province: "Overijssel",
        cbs_code: "1742",
        population: 38502,
        area: 94.13,
        migrants: 8.1,
        migrants_western: 3,
        migrants_non_western: 5.2,
        income: 0,
        latitude: 52.3,
        longitude: 6.5166666666667,
    },
    {
        title: "Rijswijk",
        province: "Zuid-Holland",
        cbs_code: "0603",
        population: 58050,
        area: 13.96,
        migrants: 36.8,
        migrants_western: 15.2,
        migrants_non_western: 21.5,
        income: 0,
        latitude: 52.033333333333,
        longitude: 4.3166666666667,
    },
    {
        title: "Roerdalen",
        province: "Limburg",
        cbs_code: "1669",
        population: 20699,
        area: 88.21,
        migrants: 17.6,
        migrants_western: 14,
        migrants_non_western: 3.6,
        income: 0,
        latitude: 51.133333333333,
        longitude: 6,
    },
    {
        title: "Roermond",
        province: "Limburg",
        cbs_code: "0957",
        population: 59992,
        area: 60.81,
        migrants: 29.7,
        migrants_western: 15,
        migrants_non_western: 14.6,
        income: 0,
        latitude: 51.2,
        longitude: 5.9833333333333,
    },
    {
        title: "Roosendaal",
        province: "Noord-Brabant",
        cbs_code: "1674",
        population: 77578,
        area: 106.5,
        migrants: 26.1,
        migrants_western: 11.9,
        migrants_non_western: 14.2,
        income: 0,
        latitude: 51.533333333333,
        longitude: 4.4666666666667,
    },
    {
        title: "Rotterdam",
        province: "Zuid-Holland",
        cbs_code: "0599",
        population: 664311,
        area: 217.55,
        migrants: 52.3,
        migrants_western: 13.4,
        migrants_non_western: 38.9,
        income: 0,
        latitude: 51.95,
        longitude: 4.4166666666667,
    },
    {
        title: "Rozendaal",
        province: "Gelderland",
        cbs_code: "0277",
        population: 1754,
        area: 27.9,
        migrants: 15.4,
        migrants_western: 9.9,
        migrants_non_western: 5.5,
        income: 0,
        latitude: 52,
        longitude: 5.9666666666667,
    },
    {
        title: "Rucphen",
        province: "Noord-Brabant",
        cbs_code: "0840",
        population: 23608,
        area: 64.41,
        migrants: 10.3,
        migrants_western: 7.1,
        migrants_non_western: 3.2,
        income: 0,
        latitude: 51.516666666667,
        longitude: 4.55,
    },
    {
        title: "Schagen",
        province: "Noord-Holland",
        cbs_code: "0441",
        population: 47477,
        area: 168.25,
        migrants: 11.4,
        migrants_western: 7,
        migrants_non_western: 4.4,
        income: 0,
        latitude: 52.783333333333,
        longitude: 4.75,
    },
    {
        title: "Scherpenzeel",
        province: "Gelderland",
        cbs_code: "0279",
        population: 10393,
        area: 13.79,
        migrants: 7.6,
        migrants_western: 4.4,
        migrants_non_western: 3.2,
        income: 0,
        latitude: 52.066666666667,
        longitude: 5.4833333333333,
    },
    {
        title: "Schiedam",
        province: "Zuid-Holland",
        cbs_code: "0606",
        population: 80723,
        area: 17.82,
        migrants: 43.7,
        migrants_western: 13.9,
        migrants_non_western: 29.8,
        income: 0,
        latitude: 51.916666666667,
        longitude: 4.4,
    },
    {
        title: "Schiermonnikoog",
        province: "Friesland",
        cbs_code: "0088",
        population: 982,
        area: 40.5,
        migrants: 10.9,
        migrants_western: 8.2,
        migrants_non_western: 2.6,
        income: 0,
        latitude: 53.466666666667,
        longitude: 6.15,
    },
    {
        title: "Schouwen-Duiveland",
        province: "Zeeland",
        cbs_code: "1676",
        population: 34521,
        area: 229.65,
        migrants: 9.9,
        migrants_western: 6.6,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 51.666666666667,
        longitude: 3.8833333333333,
    },
    {
        title: "Simpelveld",
        province: "Limburg",
        cbs_code: "0965",
        population: 10361,
        area: 16.03,
        migrants: 18.2,
        migrants_western: 15.8,
        migrants_non_western: 2.4,
        income: 0,
        latitude: 50.8833,
        longitude: 5.91666,
    },
    {
        title: "Sint-Michielsgestel",
        province: "Noord-Brabant",
        cbs_code: "0845",
        population: 30121,
        area: 58.38,
        migrants: 8.7,
        migrants_western: 5.4,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 51.633333333333,
        longitude: 5.35,
    },
    {
        title: "Sittard-Geleen",
        province: "Limburg",
        cbs_code: "1883",
        population: 92177,
        area: 79.01,
        migrants: 22.4,
        migrants_western: 15,
        migrants_non_western: 7.4,
        income: 0,
        latitude: 51,
        longitude: 5.85,
    },
    {
        title: "Sliedrecht",
        province: "Zuid-Holland",
        cbs_code: "0610",
        population: 26211,
        area: 12.84,
        migrants: 14.7,
        migrants_western: 4.9,
        migrants_non_western: 9.8,
        income: 0,
        latitude: 51.816666666667,
        longitude: 4.7666666666667,
    },
    {
        title: "Sluis",
        province: "Zeeland",
        cbs_code: "1714",
        population: 23244,
        area: 279.36,
        migrants: 28.1,
        migrants_western: 24.9,
        migrants_non_western: 3.2,
        income: 0,
        latitude: 51.333333333333,
        longitude: 3.5,
    },
    {
        title: "Smallingerland",
        province: "Friesland",
        cbs_code: "0090",
        population: 56173,
        area: 117.31,
        migrants: 11.7,
        migrants_western: 5.2,
        migrants_non_western: 6.5,
        income: 0,
        latitude: 53.166666666667,
        longitude: 6.1666666666667,
    },
    {
        title: "Soest",
        province: "Utrecht",
        cbs_code: "0342",
        population: 47423,
        area: 46.24,
        migrants: 21.6,
        migrants_western: 8.8,
        migrants_non_western: 12.9,
        income: 0,
        latitude: 52.183333333333,
        longitude: 5.2833333333333,
    },
    {
        title: "Someren",
        province: "Noord-Brabant",
        cbs_code: "0847",
        population: 20090,
        area: 80.27,
        migrants: 10.1,
        migrants_western: 7.4,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 51.383333333333,
        longitude: 5.7,
    },
    {
        title: "Son en Breugel",
        province: "Noord-Brabant",
        cbs_code: "0848",
        population: 18027,
        area: 25.95,
        migrants: 13.5,
        migrants_western: 9,
        migrants_non_western: 4.5,
        income: 0,
        latitude: 51.5,
        longitude: 5.5,
    },
    {
        title: "Stadskanaal",
        province: "Groningen",
        cbs_code: "0037",
        population: 32169,
        area: 117.64,
        migrants: 7.9,
        migrants_western: 4.7,
        migrants_non_western: 3.2,
        income: 0,
        latitude: 52.983333333333,
        longitude: 6.95,
    },
    {
        title: "Staphorst",
        province: "Overijssel",
        cbs_code: "0180",
        population: 17640,
        area: 133.99,
        migrants: 4.6,
        migrants_western: 2.5,
        migrants_non_western: 2.1,
        income: 0,
        latitude: 52.633333333333,
        longitude: 6.2,
    },
    {
        title: "Stede Broec",
        province: "Noord-Holland",
        cbs_code: "0532",
        population: 22116,
        area: 14.5,
        migrants: 13.4,
        migrants_western: 8.1,
        migrants_non_western: 5.2,
        income: 0,
        latitude: 52.7,
        longitude: 5.2166666666667,
    },
    {
        title: "Steenbergen",
        province: "Noord-Brabant",
        cbs_code: "0851",
        population: 24582,
        area: 146.43,
        migrants: 15.3,
        migrants_western: 11.4,
        migrants_non_western: 3.8,
        income: 0,
        latitude: 51.583333333333,
        longitude: 4.3166666666667,
    },
    {
        title: "Steenwijkerland",
        province: "Overijssel",
        cbs_code: "1708",
        population: 45383,
        area: 288.27,
        migrants: 8.8,
        migrants_western: 4.7,
        migrants_non_western: 4.1,
        income: 0,
        latitude: 52.783333333333,
        longitude: 6.1166666666667,
    },
    {
        title: "Stein",
        province: "Limburg",
        cbs_code: "0971",
        population: 24783,
        area: 21.08,
        migrants: 14.3,
        migrants_western: 10.8,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 50.966666666667,
        longitude: 5.75,
    },
    {
        title: "Stichtse Vecht",
        province: "Utrecht",
        cbs_code: "1904",
        population: 65755,
        area: 96.1,
        migrants: 18.8,
        migrants_western: 9.2,
        migrants_non_western: 9.6,
        income: 0,
        latitude: 52.2,
        longitude: 5,
    },
    {
        title: "Súdwest-Fryslân",
        province: "Friesland",
        cbs_code: "1900",
        population: 90873,
        area: 523.01,
        migrants: 9.3,
        migrants_western: 5.1,
        migrants_non_western: 4.2,
        income: 0,
        latitude: 53,
        longitude: 5.5333333333333,
    },
    {
        title: "Terneuzen",
        province: "Zeeland",
        cbs_code: "0715",
        population: 54990,
        area: 250.38,
        migrants: 28,
        migrants_western: 19.8,
        migrants_non_western: 8.2,
        income: 0,
        latitude: 51.3,
        longitude: 3.8333333333333,
    },
    {
        title: "Terschelling",
        province: "Friesland",
        cbs_code: "0093",
        population: 4890,
        area: 85.26,
        migrants: 7.9,
        migrants_western: 6,
        migrants_non_western: 1.9,
        income: 0,
        latitude: 53.4,
        longitude: 5.35,
    },
    {
        title: "Texel",
        province: "Noord-Holland",
        cbs_code: "0448",
        population: 13950,
        area: 162,
        migrants: 11,
        migrants_western: 7.2,
        migrants_non_western: 3.8,
        income: 0,
        latitude: 53.066666666667,
        longitude: 4.8,
    },
    {
        title: "Teylingen",
        province: "Zuid-Holland",
        cbs_code: "1525",
        population: 38521,
        area: 28.38,
        migrants: 16.3,
        migrants_western: 9.6,
        migrants_non_western: 6.7,
        income: 0,
        latitude: 52.216666666667,
        longitude: 4.5,
    },
    {
        title: "Tholen",
        province: "Zeeland",
        cbs_code: "0716",
        population: 26793,
        area: 146.71,
        migrants: 9.4,
        migrants_western: 5.7,
        migrants_non_western: 3.7,
        income: 0,
        latitude: 51.583333333333,
        longitude: 4.1166666666667,
    },
    {
        title: "Tiel",
        province: "Gelderland",
        cbs_code: "0281",
        population: 42672,
        area: 32.88,
        migrants: 27,
        migrants_western: 10.9,
        migrants_non_western: 16.2,
        income: 0,
        latitude: 51.883333333333,
        longitude: 5.4333333333333,
    },
    {
        title: "Tilburg",
        province: "Noord-Brabant",
        cbs_code: "0855",
        population: 227794,
        area: 116.17,
        migrants: 28.8,
        migrants_western: 12.2,
        migrants_non_western: 16.6,
        income: 0,
        latitude: 51.55,
        longitude: 5.0833333333333,
    },
    {
        title: "Tubbergen",
        province: "Overijssel",
        cbs_code: "0183",
        population: 21413,
        area: 147,
        migrants: 4.7,
        migrants_western: 3.3,
        migrants_non_western: 1.4,
        income: 0,
        latitude: 52.4,
        longitude: 6.7666666666667,
    },
    {
        title: "Twenterand",
        province: "Overijssel",
        cbs_code: "1700",
        population: 33867,
        area: 106.17,
        migrants: 5.4,
        migrants_western: 3.1,
        migrants_non_western: 2.3,
        income: 0,
        latitude: 52.4,
        longitude: 6.6166666666667,
    },
    {
        title: "Tynaarlo",
        province: "Drenthe",
        cbs_code: "1730",
        population: 34603,
        area: 143,
        migrants: 8.8,
        migrants_western: 5.3,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 53.066666666667,
        longitude: 6.5666666666667,
    },
    {
        title: "Tietjerksteradeel",
        province: "Friesland",
        cbs_code: "0737",
        population: 32441,
        area: 148.86,
        migrants: 6.5,
        migrants_western: 3.2,
        migrants_non_western: 3.3,
        income: 0,
        latitude: 53.183333333333,
        longitude: 5.9833333333333,
    },
    {
        title: "Uitgeest",
        province: "Noord-Holland",
        cbs_code: "0450",
        population: 13441,
        area: 19.16,
        migrants: 12.6,
        migrants_western: 6.9,
        migrants_non_western: 5.7,
        income: 0,
        latitude: 52.516666666667,
        longitude: 4.7,
    },
    {
        title: "Uithoorn",
        province: "Noord-Holland",
        cbs_code: "0451",
        population: 31406,
        area: 18.14,
        migrants: 27.1,
        migrants_western: 12.3,
        migrants_non_western: 14.7,
        income: 0,
        latitude: 52.233333333333,
        longitude: 4.8166666666667,
    },
    {
        title: "Urk",
        province: "Flevoland",
        cbs_code: "0184",
        population: 21847,
        area: 13.15,
        migrants: 4.2,
        migrants_western: 1.6,
        migrants_non_western: 2.5,
        income: 0,
        latitude: 52.65,
        longitude: 5.6,
    },
    {
        title: "Utrecht",
        province: "Utrecht",
        cbs_code: "0344",
        population: 368024,
        area: 93.83,
        migrants: 36.1,
        migrants_western: 12.2,
        migrants_non_western: 23.9,
        income: 0,
        latitude: 52.083333333333,
        longitude: 5.1166666666667,
    },
    {
        title: "Utrechtse Heuvelrug",
        province: "Utrecht",
        cbs_code: "1581",
        population: 50353,
        area: 132.01,
        migrants: 15.8,
        migrants_western: 8.5,
        migrants_non_western: 7.3,
        income: 0,
        latitude: 52.033333333333,
        longitude: 5.45,
    },
    {
        title: "Vaals",
        province: "Limburg",
        cbs_code: "0981",
        population: 10173,
        area: 23.89,
        migrants: 52.1,
        migrants_western: 46.8,
        migrants_non_western: 5.3,
        income: 0,
        latitude: 50.766666666667,
        longitude: 6.0166666666667,
    },
    {
        title: "Valkenburg aan de Geul",
        province: "Limburg",
        cbs_code: "0994",
        population: 16456,
        area: 36.73,
        migrants: 15,
        migrants_western: 11.5,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 50.85,
        longitude: 5.8166666666667,
    },
    {
        title: "Valkenswaard",
        province: "Noord-Brabant",
        cbs_code: "0858",
        population: 31548,
        area: 54.92,
        migrants: 14.5,
        migrants_western: 9.6,
        migrants_non_western: 5,
        income: 0,
        latitude: 51.35,
        longitude: 5.4666666666667,
    },
    {
        title: "Veendam",
        province: "Groningen",
        cbs_code: "0047",
        population: 27620,
        area: 76,
        migrants: 13.1,
        migrants_western: 5.4,
        migrants_non_western: 7.7,
        income: 0,
        latitude: 53.1,
        longitude: 6.8666666666667,
    },
    {
        title: "Veenendaal",
        province: "Utrecht",
        cbs_code: "0345",
        population: 68563,
        area: 19.46,
        migrants: 18.6,
        migrants_western: 6.1,
        migrants_non_western: 12.5,
        income: 0,
        latitude: 52.016666666667,
        longitude: 5.55,
    },
    {
        title: "Veere",
        province: "Zeeland",
        cbs_code: "0717",
        population: 22071,
        area: 132.56,
        migrants: 8.8,
        migrants_western: 6.2,
        migrants_non_western: 2.6,
        income: 0,
        latitude: 51.55,
        longitude: 3.5333333333333,
    },
    {
        title: "Veldhoven",
        province: "Noord-Brabant",
        cbs_code: "0861",
        population: 46452,
        area: 31.69,
        migrants: 18.4,
        migrants_western: 10.4,
        migrants_non_western: 8,
        income: 0,
        latitude: 51.4,
        longitude: 5.4,
    },
    {
        title: "Velsen",
        province: "Noord-Holland",
        cbs_code: "0453",
        population: 68857,
        area: 45.2,
        migrants: 19.5,
        migrants_western: 9.9,
        migrants_non_western: 9.6,
        income: 0,
        latitude: 52.466666666667,
        longitude: 4.6166666666667,
    },
    {
        title: "Venlo",
        province: "Limburg",
        cbs_code: "0983",
        population: 103312,
        area: 124.25,
        migrants: 28.9,
        migrants_western: 16.7,
        migrants_non_western: 12.2,
        income: 0,
        latitude: 51.366666666667,
        longitude: 6.1666666666667,
    },
    {
        title: "Venray",
        province: "Limburg",
        cbs_code: "0984",
        population: 44648,
        area: 163.27,
        migrants: 20.8,
        migrants_western: 11.5,
        migrants_non_western: 9.3,
        income: 0,
        latitude: 51.533333333333,
        longitude: 5.9666666666667,
    },
    {
        title: "Vijfheerenlanden",
        province: "Utrecht",
        cbs_code: "1961",
        population: 60184,
        area: 146.41,
        migrants: 17,
        migrants_western: 6.3,
        migrants_non_western: 10.7,
        income: 0,
        latitude: 51.933333333333,
        longitude: 5.0666666666667,
    },
    {
        title: "Vlaardingen",
        province: "Zuid-Holland",
        cbs_code: "0622",
        population: 75126,
        area: 23.57,
        migrants: 32.1,
        migrants_western: 10.7,
        migrants_non_western: 21.4,
        income: 0,
        latitude: 51.916666666667,
        longitude: 4.35,
    },
    {
        title: "Vlieland",
        province: "Friesland",
        cbs_code: "0096",
        population: 1254,
        area: 39.15,
        migrants: 15.2,
        migrants_western: 13.2,
        migrants_non_western: 2.1,
        income: 0,
        latitude: 53.466666666667,
        longitude: 5.1,
    },
    {
        title: "Vlissingen",
        province: "Zeeland",
        cbs_code: "0718",
        population: 45182,
        area: 34.31,
        migrants: 26.6,
        migrants_western: 14.4,
        migrants_non_western: 12.2,
        income: 0,
        latitude: 51.45,
        longitude: 3.5833333333333,
    },
    {
        title: "Voerendaal",
        province: "Limburg",
        cbs_code: "0986",
        population: 12419,
        area: 31.51,
        migrants: 14,
        migrants_western: 10.6,
        migrants_non_western: 3.4,
        income: 0,
        latitude: 50.883333333333,
        longitude: 5.9166666666667,
    },
    {
        title: "Voorne aan Zee",
        province: "Zuid-Holland",
        cbs_code: "1992",
        population: 73993,
        area: 174.81,
        migrants: 0,
        migrants_western: 0,
        migrants_non_western: 0,
        income: 0,
        latitude: 51.866666666667,
        longitude: 4.1333333333333,
    },
    {
        title: "Voorschoten",
        province: "Zuid-Holland",
        cbs_code: "0626",
        population: 25585,
        area: 11.14,
        migrants: 22.6,
        migrants_western: 13.1,
        migrants_non_western: 9.5,
        income: 0,
        latitude: 52.116666666667,
        longitude: 4.45,
    },
    {
        title: "Voorst",
        province: "Gelderland",
        cbs_code: "0285",
        population: 25227,
        area: 122.97,
        migrants: 8.3,
        migrants_western: 4.9,
        migrants_non_western: 3.4,
        income: 0,
        latitude: 52.233333333333,
        longitude: 6.0833333333333,
    },
    {
        title: "Vught",
        province: "Noord-Brabant",
        cbs_code: "0865",
        population: 32113,
        area: 33.75,
        migrants: 14.3,
        migrants_western: 9.2,
        migrants_non_western: 5.2,
        income: 0,
        latitude: 51.65,
        longitude: 5.2833333333333,
    },
    {
        title: "Waadhoeke",
        province: "Friesland",
        cbs_code: "1949",
        population: 46800,
        area: 284.86,
        migrants: 9.1,
        migrants_western: 5.5,
        migrants_non_western: 3.6,
        income: 0,
        latitude: 53.183333333333,
        longitude: 5.5333333333333,
    },
    {
        title: "Waalre",
        province: "Noord-Brabant",
        cbs_code: "0866",
        population: 17965,
        area: 22.39,
        migrants: 17.5,
        migrants_western: 11.1,
        migrants_non_western: 6.5,
        income: 0,
        latitude: 51.383333333333,
        longitude: 5.4333333333333,
    },
    {
        title: "Waalwijk",
        province: "Noord-Brabant",
        cbs_code: "0867",
        population: 49934,
        area: 64.58,
        migrants: 19,
        migrants_western: 10.2,
        migrants_non_western: 8.8,
        income: 0,
        latitude: 51.7,
        longitude: 5.1,
    },
    {
        title: "Waddinxveen",
        province: "Zuid-Holland",
        cbs_code: "0627",
        population: 32690,
        area: 27.77,
        migrants: 17.5,
        migrants_western: 8,
        migrants_non_western: 9.5,
        income: 0,
        latitude: 52.033333333333,
        longitude: 4.6333333333333,
    },
    {
        title: "Wageningen",
        province: "Gelderland",
        cbs_code: "0289",
        population: 41098,
        area: 30.42,
        migrants: 28.1,
        migrants_western: 14,
        migrants_non_western: 14.2,
        income: 0,
        latitude: 51.966666666667,
        longitude: 5.6666666666667,
    },
    {
        title: "Wassenaar",
        province: "Zuid-Holland",
        cbs_code: "0629",
        population: 27131,
        area: 51.11,
        migrants: 34.3,
        migrants_western: 21.9,
        migrants_non_western: 12.3,
        income: 0,
        latitude: 52.15,
        longitude: 4.3833333333333,
    },
    {
        title: "Waterland",
        province: "Noord-Holland",
        cbs_code: "0852",
        population: 17623,
        area: 52.11,
        migrants: 13.5,
        migrants_western: 7.7,
        migrants_non_western: 5.8,
        income: 0,
        latitude: 52.45,
        longitude: 5.0166666666667,
    },
    {
        title: "Weert",
        province: "Limburg",
        cbs_code: "0988",
        population: 50874,
        area: 104.32,
        migrants: 22.2,
        migrants_western: 11.5,
        migrants_non_western: 10.7,
        income: 0,
        latitude: 51.25,
        longitude: 5.7,
    },
    {
        title: "West Betuwe",
        province: "Gelderland",
        cbs_code: "1960",
        population: 52742,
        area: 216.12,
        migrants: 9.6,
        migrants_western: 5.6,
        migrants_non_western: 4,
        income: 0,
        latitude: 51.883333333333,
        longitude: 5.2833333333333,
    },
    {
        title: "West Maas en Waal",
        province: "Gelderland",
        cbs_code: "0668",
        population: 20082,
        area: 76.76,
        migrants: 8.5,
        migrants_western: 5.5,
        migrants_non_western: 2.9,
        income: 0,
        latitude: 51.883333333333,
        longitude: 5.5166666666667,
    },
    {
        title: "Westerkwartier",
        province: "Groningen",
        cbs_code: "1969",
        population: 64984,
        area: 362.69,
        migrants: 7.3,
        migrants_western: 4.3,
        migrants_non_western: 3,
        income: 0,
        latitude: 53.216666666667,
        longitude: 6.35,
    },
    {
        title: "Westerveld",
        province: "Drenthe",
        cbs_code: "1701",
        population: 19858,
        area: 278.35,
        migrants: 6.4,
        migrants_western: 4.2,
        migrants_non_western: 2.2,
        income: 0,
        latitude: 52.766666666667,
        longitude: 6.2333333333333,
    },
    {
        title: "Westervoort",
        province: "Gelderland",
        cbs_code: "0293",
        population: 15115,
        area: 7.03,
        migrants: 18.6,
        migrants_western: 9.3,
        migrants_non_western: 9.4,
        income: 0,
        latitude: 51.95,
        longitude: 5.9666666666667,
    },
    {
        title: "Westerwolde",
        province: "Groningen",
        cbs_code: "1950",
        population: 26331,
        area: 275.67,
        migrants: 13,
        migrants_western: 6.1,
        migrants_non_western: 6.9,
        income: 0,
        latitude: 53.083333333333,
        longitude: 7.1166666666667,
    },
    {
        title: "Westland",
        province: "Zuid-Holland",
        cbs_code: "1783",
        population: 114996,
        area: 81.27,
        migrants: 16,
        migrants_western: 9.2,
        migrants_non_western: 6.8,
        income: 0,
        latitude: 52,
        longitude: 4.2166666666667,
    },
    {
        title: "Weststellingwerf",
        province: "Friesland",
        cbs_code: "0098",
        population: 26500,
        area: 220.3,
        migrants: 7.8,
        migrants_western: 4.1,
        migrants_non_western: 3.8,
        income: 0,
        latitude: 52.883333333333,
        longitude: 6,
    },
    {
        title: "Wierden",
        province: "Overijssel",
        cbs_code: "0189",
        population: 24879,
        area: 94.63,
        migrants: 7.3,
        migrants_western: 4.6,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 52.35,
        longitude: 6.5833333333333,
    },
    {
        title: "Wijchen",
        province: "Gelderland",
        cbs_code: "0296",
        population: 41519,
        area: 66.18,
        migrants: 12.6,
        migrants_western: 7.6,
        migrants_non_western: 5,
        income: 0,
        latitude: 51.8,
        longitude: 5.7166666666667,
    },
    {
        title: "Wijdemeren",
        province: "Noord-Holland",
        cbs_code: "1696",
        population: 24645,
        area: 47.6,
        migrants: 14.4,
        migrants_western: 9,
        migrants_non_western: 5.4,
        income: 0,
        latitude: 52.216666666667,
        longitude: 5.1,
    },
    {
        title: "Wijk bij Duurstede",
        province: "Utrecht",
        cbs_code: "0352",
        population: 24000,
        area: 47.62,
        migrants: 11.8,
        migrants_western: 0,
        migrants_non_western: 0,
        income: 0,
        latitude: 51.983333333333,
        longitude: 5.3333333333333,
    },
    {
        title: "Winterswijk",
        province: "Gelderland",
        cbs_code: "0294",
        population: 29250,
        area: 138.14,
        migrants: 14.9,
        migrants_western: 8.7,
        migrants_non_western: 6.2,
        income: 0,
        latitude: 51.966666666667,
        longitude: 6.7166666666667,
    },
    {
        title: "Woensdrecht",
        province: "Noord-Brabant",
        cbs_code: "0873",
        population: 22178,
        area: 91.66,
        migrants: 24,
        migrants_western: 20.5,
        migrants_non_western: 3.5,
        income: 0,
        latitude: 51.433333333333,
        longitude: 4.3,
    },
    {
        title: "Woerden",
        province: "Utrecht",
        cbs_code: "0632",
        population: 53253,
        area: 88.57,
        migrants: 15.4,
        migrants_western: 6.9,
        migrants_non_western: 8.6,
        income: 0,
        latitude: 52.083333333333,
        longitude: 4.8833333333333,
    },
    {
        title: "Wormerland",
        province: "Noord-Holland",
        cbs_code: "0880",
        population: 16606,
        area: 38.59,
        migrants: 14,
        migrants_western: 6.5,
        migrants_non_western: 7.5,
        income: 0,
        latitude: 52.5,
        longitude: 4.8666666666667,
    },
    {
        title: "Woudenberg",
        province: "Utrecht",
        cbs_code: "0351",
        population: 14410,
        area: 36.53,
        migrants: 10.6,
        migrants_western: 4.8,
        migrants_non_western: 5.9,
        income: 0,
        latitude: 52.066666666667,
        longitude: 5.4,
    },
    {
        title: "Zaanstad",
        province: "Noord-Holland",
        cbs_code: "0479",
        population: 159806,
        area: 73.87,
        migrants: 33.3,
        migrants_western: 10.9,
        migrants_non_western: 22.3,
        income: 0,
        latitude: 52.466666666667,
        longitude: 4.7833333333333,
    },
    {
        title: "Zaltbommel",
        province: "Gelderland",
        cbs_code: "0297",
        population: 30315,
        area: 79.38,
        migrants: 13.9,
        migrants_western: 7.9,
        migrants_non_western: 6,
        income: 0,
        latitude: 51.816666666667,
        longitude: 5.25,
    },
    {
        title: "Zandvoort",
        province: "Noord-Holland",
        cbs_code: "0473",
        population: 17557,
        area: 32.12,
        migrants: 23.4,
        migrants_western: 14.3,
        migrants_non_western: 9,
        income: 0,
        latitude: 52.366666666667,
        longitude: 4.5333333333333,
    },
    {
        title: "Zeewolde",
        province: "Flevoland",
        cbs_code: "0050",
        population: 23719,
        area: 247.23,
        migrants: 18.4,
        migrants_western: 12.8,
        migrants_non_western: 5.6,
        income: 0,
        latitude: 52.333333333333,
        longitude: 5.5333333333333,
    },
    {
        title: "Zeist",
        province: "Utrecht",
        cbs_code: "0355",
        population: 66612,
        area: 48.51,
        migrants: 23.6,
        migrants_western: 9.7,
        migrants_non_western: 14,
        income: 0,
        latitude: 52.083333333333,
        longitude: 5.2333333333333,
    },
    {
        title: "Zevenaar",
        province: "Gelderland",
        cbs_code: "0299",
        population: 45065,
        area: 92.6,
        migrants: 14.9,
        migrants_western: 10,
        migrants_non_western: 4.9,
        income: 0,
        latitude: 51.916666666667,
        longitude: 6.0833333333333,
    },
    {
        title: "Zoetermeer",
        province: "Zuid-Holland",
        cbs_code: "0637",
        population: 127047,
        area: 34.45,
        migrants: 32.1,
        migrants_western: 10.9,
        migrants_non_western: 21.2,
        income: 0,
        latitude: 52.05,
        longitude: 4.5,
    },
    {
        title: "Zoeterwoude",
        province: "Zuid-Holland",
        cbs_code: "0638",
        population: 9465,
        area: 21.19,
        migrants: 13.1,
        migrants_western: 6.6,
        migrants_non_western: 6.4,
        income: 0,
        latitude: 52.1,
        longitude: 4.5,
    },
    {
        title: "Zuidplas",
        province: "Zuid-Holland",
        cbs_code: "1892",
        population: 47114,
        area: 58.02,
        migrants: 17.3,
        migrants_western: 8.2,
        migrants_non_western: 9.1,
        income: 0,
        latitude: 52.016666666667,
        longitude: 4.6166666666667,
    },
    {
        title: "Zundert",
        province: "Noord-Brabant",
        cbs_code: "0879",
        population: 22527,
        area: 120.65,
        migrants: 16,
        migrants_western: 12.4,
        migrants_non_western: 3.6,
        income: 0,
        latitude: 51.466666666667,
        longitude: 4.65,
    },
    {
        title: "Zutphen",
        province: "Gelderland",
        cbs_code: "0301",
        population: 48545,
        area: 40.95,
        migrants: 18.1,
        migrants_western: 8.6,
        migrants_non_western: 9.5,
        income: 0,
        latitude: 52.133333333333,
        longitude: 6.2,
    },
    {
        title: "Zwartewaterland",
        province: "Overijssel",
        cbs_code: "1896",
        population: 23390,
        area: 82.49,
        migrants: 6.2,
        migrants_western: 3.6,
        migrants_non_western: 2.7,
        income: 0,
        latitude: 52.583333333333,
        longitude: 6.0833333333333,
    },
    {
        title: "Zwijndrecht",
        province: "Zuid-Holland",
        cbs_code: "0642",
        population: 44992,
        area: 20.3,
        migrants: 23.2,
        migrants_western: 8.2,
        migrants_non_western: 15,
        income: 0,
        latitude: 51.816666666667,
        longitude: 4.65,
    },
    {
        title: "Zwolle",
        province: "Overijssel",
        cbs_code: "0193",
        population: 132454,
        area: 111.1,
        migrants: 17.4,
        migrants_western: 6.8,
        migrants_non_western: 10.5,
        income: 0,
        latitude: 52.516666666667,
        longitude: 6.0833333333333,
    },
    // {
    //     title: "Bonaire",
    //     province: "Caribisch Nederland",
    //     cbs_code: 9001,
    //     population: 22573,
    //     area: 288,
    //     migrants: 0,
    //     migrants_western: 0,
    //     migrants_non_western: 0,
    //     income: 0,
    //     latitude: 12.166666666667,
    //     longitude: 68.25,
    // },
    // {
    //     title: "Sint Eustatius",
    //     province: "Caribisch Nederland",
    //     cbs_code: 9002,
    //     population: 3242,
    //     area: 21,
    //     migrants: 0,
    //     migrants_western: 0,
    //     migrants_non_western: 0,
    //     income: 0,
    //     latitude: 17.483333333333,
    //     longitude: 62.966666666667,
    // },
    // {
    //     title: "Saba",
    //     province: "Caribisch Nederland",
    //     cbs_code: 9003,
    //     population: 1911,
    //     area: 13,
    //     migrants: 0,
    //     migrants_western: 0,
    //     migrants_non_western: 0,
    //     income: 0,
    //     latitude: 17.616666666667,
    //     longitude: 63.233333333333,
    // },
];
