import { Party } from "../types";

const parties: Party[] = [
    {
        id: 999,
        name: "Blanco",
        full: "AantalBlancoStemmen",
        isParty: false,
        color: "grey",
    },
    {
        id: 1000,
        name: "Ongeldig",
        full: "AantalOngeldigeStemmen",
        isParty: false,
        color: "grey",
    },
    {
        id: 1,
        name: "GL+PvdA",
        full: "GL+PvdA",
        isParty: true,
        color: "#a90000",
    },
    {
        id: 2,
        name: "VVD",
        full: "VVD",
        isParty: true,
        color: "#4c44ff",
    },
    {
        id: 3,
        name: "D66",
        full: "D66",
        isParty: true,
        color: "#64dcaf",
    },
    {
        id: 4,
        name: "PVV",
        full: "PVV (Partij voor de Vrijheid)",
        isParty: true,
        color: "#70d4ff",
    },
    {
        id: 5,
        name: "DENK",
        full: "DENK",
        isParty: true,
        color: "purple",
    },
    {
        id: 6,
        name: "NSC",
        full: "NSC",
        isParty: true,
        color: "#F7C106",
    },
    {
        id: 7,
        name: "PvdD",
        full: "Partij voor de Dieren",
        isParty: true,
        color: "#ffff99",
    },
    {
        id: 8,
        name: "Volt",
        full: "Volt",
        isParty: true,
        color: "#c66a6b",
    },
    {
        id: 9,
        name: "SP",
        full: "SP (Socialistische Partij)",
        isParty: true,
        color: "#f00031",
    },
    {
        id: 10,
        name: "BIJ1",
        full: "BIJ1",
        isParty: true,
        color: "#000000",
    },
    {
        id: 11,
        name: "FVD",
        full: "Forum voor Democratie",
        isParty: true,
        color: "#ad72d2",
    },
    {
        id: 12,
        name: "BBB",
        full: "BBB",
        isParty: true,
        color: "#a2c976",
    },
    {
        id: 13,
        name: "CU",
        full: "ChristenUnie",
        isParty: true,
        color: "#3ca0ff",
    },
    {
        id: 14,
        name: "CDA",
        full: "CDA",
        isParty: true,
        color: "#005c00",
    },
    {
        id: 15,
        name: "JA21",
        full: "JA21",
        isParty: true,
        color: "#003599",
    },
    {
        id: 16,
        name: "BVNL",
        full: "BVNL",
        isParty: true,
        color: "#000000",
    },
    {
        id: 17,
        name: "50PLUS",
        full: "50PLUS",
        isParty: true,
        color: "#000000",
    },
    {
        id: 18,
        name: "Piratenpartij",
        full: "Piratenpartij",
        isParty: true,
        color: "#000000",
    },
    {
        id: 19,
        name: "SGP",
        full: "Staatkundig Gereformeerde Partij (SGP)",
        isParty: true,
        color: "#f08836",
    },
    {
        id: 20,
        name: "Splinter",
        full: "Splinter",
        isParty: true,
        color: "#000000",
    },
    {
        id: 21,
        name: "LEF",
        full: "LEF",
        isParty: true,
        color: "#000000",
    },
    {
        id: 22,
        name: "NL met een plan",
        full: "NL met een plan",
        isParty: true,
        color: "#000000",
    },
    {
        id: 23,
        name: "LP",
        full: "LP",
        isParty: true,
        color: "#000000",
    },
    {
        id: 24,
        name: "PartijvdSport",
        full: "PartijvdSport",
        isParty: true,
        color: "#000000",
    },
    {
        id: 25,
        name: "PPv Basisinkomen",
        full: "PPv Basisinkomen",
        isParty: true,
        color: "#000000",
    },
    {
        id: 26,
        name: "Samen v NL",
        full: "Samen voor NL",
        isParty: true,
        color: "#000000",
    },
    // {
    //     id: 27,
    //     name: "Overige 2021",
    //     isParty: true,
    //     color: "#000000",
    // },
    {
        id: 28,
        name: "GroenLinks",
        full: "GROENLINKS",
        isParty: true,
        color: "#39a935",
    },
    {
        id: 29,
        name: "PvdA",
        full: "Partij van de Arbeid (P.v.d.A.)",
        isParty: true,
        color: "#e40006",
    },
    {
        id: 30,
        name: "Code Oranje",
        full: "CODE ORANJE",
        isParty: true,
        color: "orange",
    },
    {
        id: 31,
        name: "NIDA",
        full: "NIDA",
        isParty: true,
        color: "#000",
    },
    {
        id: 32,
        name: "JONG",
        full: "JONG",
        isParty: true,
        color: "#000",
    },
    {
        id: 33,
        name: "TROTS",
        full: "Trots op Nederland (TROTS)",
        isParty: true,
        color: "#000",
    },
    {
        id: 34,
        name: "Henk Krol",
        full: "Lijst Henk Krol",
        isParty: true,
        color: "#000",
    },
    {
        id: 35,
        name: "NLBeter",
        full: "NLBeter",
        isParty: true,
        color: "#000",
    },
    {
        id: 36,
        name: "LP",
        full: "LP (Libertaire Partij)",
        isParty: true,
        color: "#000",
    },
    {
        id: 37,
        name: "Oprecht",
        full: "OPRECHT",
        isParty: true,
        color: "#000",
    },
    {
        id: 38,
        name: "VSN",
        full: "Vrij en Sociaal Nederland",
        isParty: true,
        color: "#000",
    },
    {
        id: 39,
        name: "PvdE",
        full: "Partij van de Eenheid",
        isParty: true,
        color: "#000",
    },
    {
        id: 40,
        name: "U-Buntu",
        full: "U-Buntu Connected Front",
        isParty: true,
        color: "#000",
    },
    {
        id: 41,
        name: "DFP",
        full: "DE FEESTPARTIJ (DFP)",
        isParty: true,
        color: "#000",
    },
    {
        id: 42,
        name: "Jezus Leeft",
        full: "JEZUS LEEFT",
        isParty: true,
        color: "#000",
    },
    {
        id: 43,
        name: "Lijst 30",
        full: "Blanco (Zeven, A.J.L.B.)",
        isParty: true,
        color: "#000",
    },
];

export default parties;
