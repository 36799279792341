import { Election } from "../types";

const elections: Election[] = [
    {
        id: 2,
        year: 2021,
        type: "tweede-kamer",
        loaded: false,
        url: "data/votes-2021.json",
        voteSets: [],
        results: [
            {
                party_id: 999,
                votes: 17173,
            },
            {
                party_id: 1000,
                votes: 22652,
            },
            {
                party_id: 2,
                votes: 2279126,
            },
            {
                party_id: 3,
                votes: 1565862,
            },
            {
                party_id: 4,
                votes: 1124482,
            },
            {
                party_id: 28,
                votes: 537308,
            },
            {
                party_id: 5,
                votes: 211238,
            },
            {
                party_id: 29,
                votes: 597192,
            },
            {
                party_id: 14,
                votes: 990601,
            },
            {
                party_id: 7,
                votes: 399751,
            },
            {
                party_id: 11,
                votes: 523083,
            },
            {
                party_id: 9,
                votes: 623371,
            },
            {
                party_id: 8,
                votes: 252480,
            },
            {
                party_id: 13,
                votes: 351275,
            },
            {
                party_id: 15,
                votes: 246620,
            },
            {
                party_id: 10,
                votes: 87238,
            },
            {
                party_id: 30,
                votes: 40731,
            },
            {
                party_id: 31,
                votes: 33834,
            },
            {
                party_id: 17,
                votes: 106702,
            },
            {
                party_id: 19,
                votes: 215249,
            },
            {
                party_id: 20,
                votes: 30328,
            },
            {
                party_id: 18,
                votes: 22816,
            },
            {
                party_id: 32,
                votes: 15297,
            },
            {
                party_id: 12,
                votes: 104319,
            },
            {
                party_id: 33,
                votes: 13198,
            },
            {
                party_id: 34,
                votes: 9264,
            },
            {
                party_id: 35,
                votes: 8657,
            },
            {
                party_id: 36,
                votes: 5546,
            },
            {
                party_id: 37,
                votes: 5449,
            },
            {
                party_id: 38,
                votes: 942,
            },
            {
                party_id: 39,
                votes: 804,
            },
            {
                party_id: 40,
                votes: 1880,
            },
            {
                party_id: 43,
                votes: 8277,
            },
            {
                party_id: 41,
                votes: 3744,
            },
            {
                party_id: 42,
                votes: 5015,
            },
        ],
    },
    {
        id: 1,
        year: 2023,
        type: "tweede-kamer",
        loaded: false,
        url: "data/votes-2023.json",
        voteSets: [],
        results: [
            {
                party_id: 999,
                votes: 0,
            },
            {
                party_id: 1000,
                votes: 0,
            },
            {
                party_id: 1,
                votes: 1620585,
            },
            {
                party_id: 2,
                votes: 1575991,
            },
            {
                party_id: 3,
                votes: 647104,
            },
            {
                party_id: 4,
                votes: 2441533,
            },
            {
                party_id: 5,
                votes: 246335,
            },
            {
                party_id: 6,
                votes: 1329710,
            },
            {
                party_id: 7,
                votes: 231870,
            },
            {
                party_id: 8,
                votes: 174944,
            },
            {
                party_id: 9,
                votes: 325853,
            },
            {
                party_id: 10,
                votes: 43996,
            },
            {
                party_id: 11,
                votes: 229958,
            },
            {
                party_id: 12,
                votes: 481433,
            },
            {
                party_id: 13,
                votes: 210901,
            },
            {
                party_id: 14,
                votes: 343581,
            },
            {
                party_id: 15,
                votes: 70637,
            },
            {
                party_id: 16,
                votes: 52739,
            },
            {
                party_id: 17,
                votes: 50839,
            },
            {
                party_id: 18,
                votes: 9115,
            },
            {
                party_id: 19,
                votes: 216773,
            },
            {
                party_id: 20,
                votes: 13299,
            },
            {
                party_id: 21,
                votes: 5026,
            },
            {
                party_id: 22,
                votes: 5437,
            },
            {
                party_id: 23,
                votes: 4142,
            },
            {
                party_id: 24,
                votes: 3960,
            },
            {
                party_id: 25,
                votes: 1025,
            },
            {
                party_id: 26,
                votes: 5319,
            },
            {
                party_id: 27,
                votes: 0,
            },
        ],
    },
];

export default elections;
